import { Component } from "@angular/core";
import { FOBlotterService } from "../services/fo-blotter.service";
import { FOFilterModel } from "../model/fo-filter.model";
import { SharedService } from "../services/shared.service";

import * as moment from 'moment';
import { LookupsService } from "../shared/lookups.service.component";
import { ReferenceDataService } from "../services/reference-data.service";
import { ConfigurationService } from "projects/shared/src/lib/services/config.service";

import { EmailPreviewComponent } from "../email-preview/email-preview.component";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'fo-blotters',
    templateUrl: './fo-blotter-list.component.html'
})
export class FOBlotterListComponent {

    public blotters: any[] = [];
    private timeout: number = 5;

    public first: number = 0;
    public rows: number = 50;
    public totalRows: number = 0;
    public filterModel: FOFilterModel = new FOFilterModel();
    public traderSwaps: any[] = [];


    public windowWidth = 600;
    public windowHeight = 500;
    public opened = false;
    public previewUrl!: string;

    constructor(private blotterService: FOBlotterService,
        private sharedService: SharedService,
        public lookupService: LookupsService,
        public configService: ConfigurationService,
        private sanitizer: DomSanitizer,
        private _refDataService: ReferenceDataService) { }

    ngOnInit() {

        this.getTraderswap();
        this.filterModel.pageNo = this.first;
        this.filterModel.pageSize = this.rows;
        this.filterModel.fromTradeDate = moment().format("YYYY-MM-DD");
        this.filterModel.toTradeDate = moment().format("YYYY-MM-DD");
        this.filterModel.traderSwap = this.sharedService.getUserDetails.defaultBroker;

        this.search();

        setInterval(() => this.search(), 5000);
    }

    public search() {
        this.blotterService.getBlotters(this.filterModel)
            .subscribe(response => {
                this.blotters = response;
                if (response.length > 0) {
                    this.totalRows = response[0].totalRows;
                    this.blotters = response;
                } else {
                    this.totalRows = 0;
                    this.blotters = [];

                }
            });
    }

    public onPageChange(event: any) {
        this.first = event.first;
        this.filterModel.pageNo = event.page;
        this.search();
    }

    setContract($event: any) {
        this.filterModel.contract = $event;
    }

    getTraderswap() {
        if (this.sharedService.getUserDetails.defaultBroker == "BACKOFFICE") {
            this._refDataService.getTraderSwaps()
                .subscribe((response: any) => {
                    this.traderSwaps = response;
                });

        } else {
            this.traderSwaps = [];
            this.traderSwaps.push({ name: this.sharedService.getUserDetails.defaultBroker });
            this.filterModel.traderSwap = this.sharedService.getUserDetails.defaultBroker;
        }
        // this._refDataService.getTraderSwaps()
        // 	.subscribe((response: any) => {
        // 		this.traderSwaps = response;
        // 	});
    }


    public showEmailPreview(id: number, traderSwap: string) {
        var url = this.configService.get('recapUrl') + `/${id}/${traderSwap}`;

        // var windowRef = this.windowService.open({
        //     title: "Confirmations",
        //     content: EmailPreviewComponent,
        //     height: 800,
        //     width: 900,
        //     resizable: true,
        //     state: "maximized"
        // })

        // const instance = windowRef.content.instance;
        // instance.url = url;

        //https://appwa-ui-confirms-dev-useqoptions-eus2-01.azurewebsites.net/preview/
    }

    public url(id: number, traderSwap: string): string {
        this.previewUrl = this.configService.get('recapUrl') + `/${id}/${traderSwap}`
        return this.previewUrl;
        this.opened = true;
    }
    public openClose(isOpened: boolean): void {
        this.opened = isOpened;
    }

}