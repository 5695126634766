<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
    integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css">

<style>
    @page {
        size: A5 landspace
    }
</style>

<div *ngIf="showTable">

    <div *ngFor="let printBlotter of printBlotters; let i=index"
        style="page-break-after: always !important; padding: 16px !important;">
        <table class="table table-borderless">
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td style="max-width: 1px !important;"></td>
                <td>&nbsp;</td>
                <td style="font-size: xx-small; color: silver;text-align: right;"><span
                        style="width: 100% !important;">{{i
                        + 1}} of {{printBlotters.length}}</span></td>
            </tr>
            <tr>
                <td style="vertical-align: bottom;">
                    Order: <span class="border border-1 border-secondary"
                        style="padding:2px !important">{{printBlotter.orderNo}}</span>
                </td>
                <td style="vertical-align: bottom;" colspan="4">
                    <div class="hstack gap-4">
                        Acct: <span class="border-bottom border-secondary">
                            {{printBlotter.account}}
                            <span>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span>Trader <span class="fs-5 text">MXA</span></span>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <span class="fs-6 text">J40</span>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                Firm: <span class="fs-6 text">004</span>
                            </span>
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="text-center">
                    <span class="w-100 fs-2 text">BUY</span>
                </td>
                <td style="max-width: 1px !important;"></td>
                <td colspan="2" class="text-center">
                    <span class="w-100 fs-2 text">SELL</span>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="border border-secondary" style="padding: 0px !important">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th class=" text-decoration-underline">Qty.</th>
                                <th class="text-decoration-underline">Month</th>
                                <th class="text-decoration-underline">Strike</th>
                                <th class="text-decoration-underline">Type</th>
                                <th class="text-decoration-underline">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let summary of printBlotter.buys">
                                <td>{{summary.sumQty}}</td>
                                <td>{{summary.bbCode}}</td>
                                <td>{{summary.sumStrike}}</td>
                                <td>{{summary.callPut}}</td>
                                <td>{{summary.sumPrice}}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td style="max-width: 1px !important;"></td>
                <td colspan="2" class="border border-secondary">
                    <table class="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th class="text-decoration-underline">Qty.</th>
                                <th class="text-decoration-underline">Month</th>
                                <th class="text-decoration-underline">Strike</th>
                                <th class="text-decoration-underline">Type</th>
                                <th class="text-decoration-underline">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let summary of printBlotter.sells">
                                <td>{{summary.sumQty}}</td>
                                <td>{{summary.bbCode}}</td>
                                <td>{{summary.sumStrike}}</td>
                                <td>{{summary.callPut}}</td>
                                <td>{{summary.sumPrice}}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <table class="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-decoration-underline">Qty.</th>
                                <th class="text-decoration-underline">Seller</th>
                                <th class="text-decoration-underline">Firm</th>
                                <th class="text-decoration-underline">Bkt.</th>
                                <th class="text-decoration-underline"><span class="badge badge-secondary">BK</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let summary of printBlotter.buyBlotters">
                                <td style="vertical-align: middle;"><img src="/assets/css/checkbox.png" width="12"
                                        height="12">
                                </td>
                                <td>{{summary.qty}}</td>
                                <td>{{summary.brokerSymbol}}</td>
                                <td>{{summary.firm}}</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td style="max-width: 1px !important;"></td>
                <td colspan="2" style="vertical-align: top;">
                    <table class="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-decoration-underline">Qty.</th>
                                <th class="text-decoration-underline">Buyer</th>
                                <th class="text-decoration-underline">Firm</th>
                                <th class="text-decoration-underline">Bkt.</th>
                                <th class="text-decoration-underline"><span class="badge badge-secondary">BK</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let summary of printBlotter.sellBlotters">
                                <td style="vertical-align: middle;"><img src="/assets/css/checkbox.png" width="12"
                                        height="12">
                                </td>
                                <td>{{summary.qty}}</td>
                                <td>{{summary.brokerSymbol}}</td>
                                <td>{{summary.firm}}</td>
                                <td>{{summary.bracketTime}}</td>
                                <td>&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </table>
    </div>
</div>