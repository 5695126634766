import { Injectable, ViewContainerRef } from "@angular/core";
import { asyncScheduler } from "rxjs";
import { PrintBlotterComponent } from "../print-blotter/print-blotter.component";

@Injectable({
    providedIn: 'root',
})
export class PrintService {

    constructor() { }

    public print(model: any, container: ViewContainerRef) {
        const componentRef = container.createComponent(PrintBlotterComponent);
        componentRef.instance.model = model;
        const task = () => {
            const htmlString = componentRef.location.nativeElement.innerHTML;

            var newWin = window.open('', 'Print-Window');
            newWin?.document.open();
            newWin?.document.write('<html><body onload="window.print()"><style>table{ font-size: 11px !important;}</style>' + htmlString + '</body></html>');
            componentRef.destroy();
            newWin?.print();
            newWin?.close();
        };

        asyncScheduler.schedule(task, 1000);
    }

    public printById(ids: number[], container: ViewContainerRef) {
        const componentRef = container.createComponent(PrintBlotterComponent);
        componentRef.instance.ids = ids;
        const task = () => {
            const htmlString = componentRef.location.nativeElement.innerHTML;

            var newWin = window.open('', 'Print-Window');
            newWin?.document.open();
            newWin?.document.write('<html><body onload="window.print()"><style>table{ font-size: 11px !important;}</style>' + htmlString + '</body></html>');
            componentRef.destroy();
            newWin?.print();
            newWin?.close();
        };

        asyncScheduler.schedule(task, 1000);
    }
}