import { Injectable } from "@angular/core";

export interface OrderButtonStatusModel {
    caption: string;
    lifeCycleStatus: string;
    isEditedMode: boolean;
    isNewMode: boolean;
    isCancelMode: boolean;
    show: boolean;
    tag: string;
    class: string;
    icon: string;
    title?: string;
}


export class OrderButtonStatusService {

    public buttons: OrderButtonStatusModel[] = [];
    private originalStatus: OrderButtonStatusModel[] = [];
    constructor() {
        this.buttons = this.getListOfButtons();
        this.originalStatus = this.getListOfButtons();
    }

    public resetStatus() {
        this.buttons = this.originalStatus;
    }

    private getListOfButtons(): OrderButtonStatusModel[] {

        return [
            {
                caption: "Save Draft",
                lifeCycleStatus: "PENDING",
                isEditedMode: false,
                isNewMode: true,
                isCancelMode: false,
                show: true,
                tag: "SAVE_DRAFT",
                class: "btn-primary",
                icon: "pi-save"
            },
            {
                caption: "Start Working Order",
                lifeCycleStatus: "PENDING",
                isEditedMode: false,
                isNewMode: true,
                isCancelMode: false,
                show: true,
                tag: "START_EVENTS",
                class: "btn-primary",
                icon: "pi-arrow-right"
            },
            {
                caption: "Save",
                lifeCycleStatus: "LIVE",
                isEditedMode: true,
                isNewMode: false,
                isCancelMode: false,
                show: true,
                tag: "SAVE",
                class: "btn-primary",
                icon: "pi-save"
            },
            {
                caption: "Mark Completed",
                lifeCycleStatus: "LIVE",
                isEditedMode: true,
                isNewMode: false,
                isCancelMode: false,
                show: true,
                tag: "CONFIRMED",
                class: "btn-success",
                icon: "pi-check"
            },
            {
                caption: "Cancel Order",
                lifeCycleStatus: "LIVE",
                isEditedMode: false,
                isNewMode: false,
                isCancelMode: true,
                show: true,
                tag: "CANCEL_ORDER",
                class: "btn-danger",
                icon: "pi-times"
            }
            // ,
            // {
            //     caption: "",
            //     lifeCycleStatus: "LIVE",
            //     isEditedMode: true,
            //     isNewMode: false,
            //     isCancelMode: true,
            //     show: true,
            //     tag: "RELOAD_ORDER",
            //     class: "btn-light",
            //     icon: "pi-refresh",
            //     title: "Reload"
            // }
        ];
    }
}