import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailPreviewComponent } from './email-preview/email-preview.component';
import { LoginComponent } from './login/login.component';
import { PrintBlotterComponent } from './print-blotter/print-blotter.component';
import { ConfirmedBlotterComponent } from './confirmed-blotter/confirmed-blotter.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BlotterListComponent } from './blotter-list/blotter-list.component';
import { AuditListComponent } from './audit-list/audit-list..component';
import { FOBlotterListComponent } from './fo-blotter-list/fo-blotter-list.component';

const routes: Routes = [
  {
    path: 'home',
    component: BlotterListComponent,
    title: 'Blotter Entry',
    canActivate: [MsalGuard]
  },
  {
    path: 'email',
    component: EmailPreviewComponent,
    title: 'Email Preview',
    canActivate: [MsalGuard]
  },
  {
    path: 'blotter/list',
    component: ConfirmedBlotterComponent,
    title: 'Confirmed Blotters',
    canActivate: [MsalGuard]
  },
  {
    path: 'print',
    component: PrintBlotterComponent,
    title: 'Print',
    canActivate: [MsalGuard]
  },
  {
    path: 'audits/:id',
    component: AuditListComponent,
    title: 'Audits',
    canActivate: [MsalGuard]
  },
  {
    path: 'confirmations',
    component: FOBlotterListComponent,
    title: "Confirmations",
    canActivate: [MsalGuard]
  },
  {
    path: '',
    component: LoginComponent,
  }
];

@NgModule({
  /* Changes start here. */
  // Replace the following line with the next one
  //imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  /* Changes end here. */
  exports: [RouterModule]
})
export class AppRoutingModule { }
