import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth.config";
import { ConfigurationService } from "../services/config.service";

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(configService: ConfigurationService): IPublicClientApplication {
    try {
        console.info("Found the contents")
        const msal = configService.get('msal');
        msalConfig.auth.clientId = msal.clientId;
        msalConfig.auth.authority = msal.authority;
    } catch (error) {
        console.info("oops...., re-reading the contents...");
        let msal!: any;
        configService.load().then(response => {
            msal = configService.get('msal');
            msalConfig.auth.clientId = msal.clientId;
            msalConfig.auth.authority = msal.authority;

        });
    }

    return new PublicClientApplication(msalConfig);
}
