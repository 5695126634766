import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenResponseModel, UserDetails } from '../model/user-detail.model';
import { ConfigurationService } from 'projects/shared/src/lib/services/config.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AccountService {
	private _url = "";
	public tokenKey = 'userDetails';
	public userOnlineStatusChange: EventEmitter<boolean>;

	constructor(private httpClient: HttpClient, config: ConfigurationService) {
		this._url = config.get('apiUrl');
		this.userOnlineStatusChange = new EventEmitter<boolean>();
	}


	// public loginUser(username: string, password: string) {

	// 	var url = this._url + '/api/user';
	// 	return this.httpClient.post<TokenResponseModel>(url, {
	// 		"userName": username,
	// 		"password": password
	// 	})
	// 		.subscribe((response: any) => {
	// 			localStorage.setItem(this.tokenKey, JSON.stringify(response));
	// 			localStorage.setItem("access_Token", response.access_Token);
	// 			this.userOnlineStatusChange.emit(true);
	// 		});
	// }

	public getUserDetails(username: string): Observable<TokenResponseModel> {

		var url = this._url + `/api/user/${username}`;
		return this.httpClient.get<TokenResponseModel>(url);
	}

	public getAuthorizationHeader(): any {
		const token = this.getAccessToken();
		if (token == null) {
			return null;
		}

		return 'Bearer ' + token;
	}

	private getAccessToken(): string {
		const tokenResponse = this.getLocalStorageToken();
		if (tokenResponse == null) {
			return '';
		}

		return tokenResponse.access_token;
	}

	private getLocalStorageToken() {
		const storage = localStorage.getItem(this.tokenKey);
		return storage != null ? JSON.parse(storage) : null;
	}

	public isUserLoggedIn() {
		return this.getLocalStorageToken() !== null;
	}

	public logout() {
		localStorage.removeItem(this.tokenKey);
	}

	public getLoggerUserName() {
		const tokenResponse = this.getLocalStorageToken();
		if (tokenResponse == null) {
			return null;
		}

		return tokenResponse.user.username;
	}

	public getLoggedUserDetail() {
		const tokenResponse = this.getLocalStorageToken();
		if (tokenResponse == null) {
			return null;
		}

		return tokenResponse.user;
	}

}
