<p-overlayPanel #op [showCloseIcon]="false" [style]="{ width: '400px' }">
    <ng-template pTemplate="content">

        <ul *ngIf="_errorMessages.length > 0" class="list-group">
            <li *ngFor="let errorMessage of _errorMessages" class="list-group-item list-group-item-danger">
                {{errorMessage}}
            </li>
        </ul>

        <div></div>
        <ul class="list-group">
            <li class="list-group-item list-group-item-info" *ngIf="orderTime">
                <strong class="text-danger"><i class="pi pi-info"></i></strong>&nbsp; The date should be >=
                {{orderTime}}
            </li>
            <li class="list-group-item list-group-item-info">
                <i class="pi pi-info"></i>&nbsp; The date can be only today's date
            </li>
        </ul>
    </ng-template>
</p-overlayPanel>

<span class="input-group mb-3">
    <button *ngIf="_errorMessages.length > 0" (click)="op.toggle($event)"
        class="btn btn-sm btn-danger input-group-text">
        <i class="pi pi-exclamation-circle"></i>
    </button>
    <p-inputMask [ngClass]="{'d-flex border border-danger': _errorMessages.length > 0}" #inputElement
        [ngStyle]="{'border-red': _errorMessages.length > 0}" mask="9999-99-99T99:99:99.999" (onBlur)="validate()"
        (onFocus)="op.toggle($event)" (onKeydown)="change(inputElement.value)" [(ngModel)]="value"
        placeholder="yyyy-MM-ddTHH:mm:ss.SSS" slotChar="yyyy-MM-ddTHH:mm:ss.SSS" [showClear]="false" />
</span>