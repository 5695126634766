import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import * as moment from 'moment';

@Component({
    templateUrl: './top-navigation.component.html',
    selector: 'top-navigation',
    styleUrls: ['./top-navigation.component.css']
})
export class TopNavigationComponent {

    title = 'JBDrax::::US Equity Options';
    public isIframe = false;
    @Input()
    public loginDisplay = false;
    @Input()
    public username!: string | undefined;
    @Output() loginEvent = new EventEmitter<string>();
    @Output() logoutEvent = new EventEmitter<string>();
    public currentTime: string = "";
    ngOnInit() {
        setInterval(() => {
            this.currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
        }, 1000);
    }
    public login() {
        this.loginEvent.emit();
    }

    public logout() {
        this.logoutEvent.emit();
    }


}