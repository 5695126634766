export class UserDetails {
    username!: string;
    defaultBroker?: string;
    email?: string;
    isMiddleOffice?: boolean;
    token?: string;
    emailAddress?: string;
}

export class TokenResponseModel {
    user!: UserDetails;
    access_Token?: string;
    expires_In?: number;
}