<div class="d-flex justify-content-center" [formGroup]="formGroup">
    <div class="card w-70 border border-warning">
        <div class="card-header bg-warning text-dark">
            <h5>Are you sure you want to cancel
                order:
                {{orderDetailForm.get('orderNo')?.value}}?</h5>
        </div>
        <div class="card-body" formGroupName="orderDetail">
            <div class="alert alert-danger" *ngIf="_errorMessages.length > 0">
                <ul>
                    <li *ngFor="let errorMessage of _errorMessages">
                        {{errorMessage}}
                    </li>
                </ul>
            </div>
            <button class="btn btn-primary mb-2" (click)="setAllToCurrentTime()">Set all to current time</button>
            <div class="hstack gap-2">
                <label for="" class="col-label-form">Cancel Request
                    On&nbsp;</label>
                <p-inputMask mask="{{dateTimeService.DateTimeMask}}" styleClass="table-date-field-with-time"
                    placeholder="{{dateTimeService.DateTimeMaskPlaceholder}}"
                    slotChar="{{dateTimeService.DateTimeMaskPlaceholder}}" [showClear]="false"
                    formControlName="cancelRequestOn" />

                <label for="" class="col-label-form">Cancel Ack
                    On&nbsp;</label>
                <p-inputMask mask="{{dateTimeService.DateTimeMask}}" styleClass="table-date-field-with-time"
                    placeholder="{{dateTimeService.DateTimeMaskPlaceholder}}"
                    slotChar="{{dateTimeService.DateTimeMaskPlaceholder}}" [showClear]="false"
                    formControlName="clientCancellationAckOn" />

                <label for="" class="col-label-form">Cancelled
                    On&nbsp;</label>
                <p-inputMask mask="{{dateTimeService.DateTimeMask}}"
                    placeholder="{{dateTimeService.DateTimeMaskPlaceholder}}" styleClass="table-date-field-with-time"
                    slotChar="{{dateTimeService.DateTimeMaskPlaceholder}}" [showClear]="false"
                    formControlName="cancelledOn" />
                <!-- <label for="" class="col-label-form">Leaves Qty&nbsp;</label>

                <p-inputNumber formControlName="leavesQty" mode="decimal" [size]="6"
                    [style]="{'max-width': '70px !important;'}" [minFractionDigits]="0" [maxFractionDigits]="4">
                </p-inputNumber> -->
            </div>
            <div class="mb-2"></div>
            <div class="alert alert-info">
                All cancellation times have to be > {{tradesForm.controls[0].get('routeTime')?.value}}
            </div>
        </div>

        <div class="card-footer">
            <button class="btn btn-primary" (click)="cancelOrder(orderDetailForm.get('orderNo')?.value)">Continue
                with Cancel</button>
            <button class=" btn btn-danger" (click)="exit()">No</button>
        </div>
    </div>
</div>