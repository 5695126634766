import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BlotterService } from '../services/blotter.service';
import { PrintService } from '../services/print.service';
import { ReferenceDataService } from '../services/reference-data.service';
import { SharedService } from '../services/shared.service';
import { TradeModel, TradeModelGroup } from '../model/trade.model';
import { DateTimeConstantService } from '../constants/date-time.constant.service';
import { LookupsService } from '../shared/lookups.service.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuditListComponent } from '../audit-list/audit-list..component';

@Component({
	selector: 'confirmed-blotter',
	templateUrl: './confirmed-blotter.component.html',
	styleUrls: ['./confirmed-blotter.component.css']
})
export class ConfirmedBlotterComponent implements OnInit {

	public orders: TradeModelGroup[] = [];
	public traderSwaps!: any[];
	public contracts!: any[];
	public blotterListSearchForm!: FormGroup;
	public selectedSearchButton: any;
	public first: number = 0;
	public rows: number = 50;
	public totalRows: number = 0;
	public showCopyOrder: boolean = false;
	public showCopyButton: boolean = false;
	copyOrderNo: string = "";
	public DATE_FORMAT_YMD_HMSS = "yyyy-MM-ddTHH:mm:ss.SSS";
	public DATE_FORMAT_YMD = "yyyy-MM-dd";
	@ViewChild('copyOrderNoInput') copyOrderNoInput!: ElementRef;
	dynamicDialogRef: DynamicDialogRef | undefined;

	public searchButtonConfigs: any = [
		{
			caption: "Today",
			days: 0,
			startDate: null,
			endDate: null,
			isSelected: true
		},
		{
			caption: "Ten Days",
			days: -11,
			startDate: null,
			endDate: null,
			isSelected: false
		}
	];
	constructor(private _blotterService: BlotterService,
		private _sharedService: SharedService,
		private _fb: FormBuilder,
		private _refDataService: ReferenceDataService,
		private _printService: PrintService,
		private _container: ViewContainerRef,
		private _dateTimeConstantService: DateTimeConstantService,
		public dialogService: DialogService,
		public lookupService: LookupsService) { }

	ngOnInit(): void {

		this._sharedService.removeEmailPreviewOrders();
		this.blotterListSearchForm = this._fb.group({
			id: new FormControl(null),
			fromTradeDate: new FormControl(null, [Validators.required]),
			toTradeDate: new FormControl(null, [Validators.required]),
			orderNo: new FormControl(null),
			side: new FormControl(null),
			contract: new FormControl(null),
			callPut: new FormControl(null),
			traderSwap: new FormControl(null),
			broker: new FormControl(null),
			pageNo: new FormControl(this.first, [Validators.required]),
			pageSize: new FormControl(this.rows, [Validators.required]),
			userName: new FormControl(null, [Validators.required]),
		});

		this.blotterListSearchForm.get('userName')?.setValue(this._sharedService.getUserDetails.username);
		this.blotterListSearchForm.get('broker')?.setValue(this._sharedService.getUserDetails.defaultBroker);
		this.blotterListSearchForm.get('fromTradeDate')?.setValue(new Date());
		this.blotterListSearchForm.get('toTradeDate')?.setValue(new Date())

		this.blotterListSearchForm?.valueChanges.subscribe((contract: any) => {
			this.first = 0;
			this.loadBlotters();
		});

		this.loadBlotters();
		this.getContracts();
		this.getTraderswap();

		this.selectedSearchButton = this.searchButtonConfigs[0];
	}

	clearContract() {
		this.blotterListSearchForm.get('contract')?.setValue(null);
	}
	setContract($event: any) {
		this.blotterListSearchForm.get('contract')?.setValue($event);
	}

	getTraderswap() {
		if (this._sharedService.getUserDetails.defaultBroker == "BACKOFFICE") {
			this._refDataService.getTraderSwaps()
				.subscribe((response: any) => {
					this.traderSwaps = response;
				});

		} else {
			this.traderSwaps = [];
			this.traderSwaps.push({ name: this._sharedService.getUserDetails.defaultBroker });
			this.blotterListSearchForm.get('traderSwap')?.setValue(this._sharedService.getUserDetails.defaultBroker);
			this.blotterListSearchForm.get('traderSwap')?.disable();
		}
		// this._refDataService.getTraderSwaps()
		// 	.subscribe((response: any) => {
		// 		this.traderSwaps = response;
		// 	});
	}

	getContracts() {
		this._refDataService.getHotKeyContracts()
			.subscribe((response: any) => {
				this.contracts = response;
			})
	}

	resetFilters() {
		this.blotterListSearchForm.get('orderNo')?.setValue(null);
		this.blotterListSearchForm.get('side')?.setValue(null);
		this.blotterListSearchForm.get('contract')?.setValue(null);
		this.blotterListSearchForm.get('callPut')?.setValue(null);
		this.blotterListSearchForm.get('traderSwap')?.setValue(null);

		this.searchButtons(this.selectedSearchButton);
	}

	loadBlotters() {

		const rawValue = this.blotterListSearchForm.getRawValue();
		rawValue.fromTradeDate = moment(this.blotterListSearchForm.get('fromTradeDate')?.value).format("YYYY-MM-DD");
		rawValue.toTradeDate = moment(this.blotterListSearchForm.get('toTradeDate')?.value).format("YYYY-MM-DD");
		rawValue.isBackOffice = this._sharedService.getUserDetails.defaultBroker == "BACKOFFICE";
		//rawValue.pageNo = this.gridState.skip;
		//rawValue.pageSize = this.gridState.take;
		this._blotterService.getConfirmedBlottersGroupedByOrderNo(rawValue)
			.subscribe((response: TradeModelGroup[]) => {
				let totalRows: number = 0;
				if (response.length > 0) {
					this.totalRows = response[0].blotters[0].totalRows;
					this.orders = response;
				} else {
					this.totalRows = 0;
					this.orders = [];

				}

			});
	}

	searchButtons(searchConfig: any) {
		this.searchButtonConfigs.forEach((result: any) => {
			result.isSelected = false;
		});


		searchConfig.isSelected = true;
		this.selectedSearchButton = searchConfig;


		if (searchConfig.caption == "Today") {
			this.blotterListSearchForm.get('fromTradeDate')?.setValue(new Date());
			this.blotterListSearchForm.get('toTradeDate')?.setValue(new Date());
		}

		if (searchConfig.caption == "Ten Days") {
			var fromDate = moment().add(searchConfig.days, 'd').toDate();
			this.blotterListSearchForm.get('fromTradeDate')?.setValue(fromDate);
			const toDate = moment().add(-1, 'd').toDate();
			this.blotterListSearchForm.get('toTradeDate')?.setValue(toDate);

		}

		this.loadBlotters();

	}

	public onPageChange(event: any) {
		this.first = event.first;
		this.blotterListSearchForm.get('pageNo')?.setValue(event.first);
		this.loadBlotters();
	}

	show(id: number) {
		console.log(id);
		this.dynamicDialogRef = this.dialogService.open(AuditListComponent, {
			header: 'Audit list',
			width: '100%',
			contentStyle: { overflow: 'auto' },
			baseZIndex: 10000,
			data: {
				blotterId: id
			},
			maximizable: true
		});

	}

	copyOrder(orderNo: string) {
		this._blotterService.copyOrder(orderNo, this._sharedService.getUserDetails.username)
			.subscribe((response: any) => {
				alert(`"Order is copied and new order with # ${response}  available in 'Working Orders' screen"`);
			})
	}

}
