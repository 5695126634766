<div [formGroup]="_formGroup" *ngIf="_formGroup" style="max-width: 99% !important;">
	<div class="card mb-2 mt-2 border-1">
		<div class="card-header" formGroupName="orderDetail">

			<div>
				<div class="row">
					<div class="col-auto mt-2 mr-1">

						<span class="shadow badge rounded-pill text-bg-success"
							*ngIf="orderDetailForm.get('lifeCycleStatus')?.value == 'LIVE'">
							&nbsp;
						</span>
						<span class="shadow badge text-bg-danger rounded-pill"
							*ngIf="orderDetailForm.get('lifeCycleStatus')?.value !== 'LIVE'">
							&nbsp;
						</span>
						&nbsp;
						<span class="shadow badge rounded-pill text-bg-primary">
							{{orderDetailForm.get('orderNo')?.value}}
						</span>
					</div>
					<!-- <div class="col-auto">
						<div class="row">
							<div class="col-auto">
								<label class="col-form-label">Order #</label>
							</div>
							<div class="col-auto">
								<input type="text" class="form-control" style="max-width: 80px !important;"
									formControlName="orderNo">
							</div>
						</div>
					</div> -->
					<div class="col-auto">
						<div class="row align-items-center">
							<div class="col-auto">
								<label class="col-form-label">Client</label>
							</div>
							<div class="col-auto">
								<input type="text" class="form-control"
									style="max-width: 210px !important; min-width: 210px !important;"
									formControlName="endClient">
							</div>
						</div>
					</div>
					<div class="col-auto">
						<div class="row align-items-center">
							<div class="col-auto">
								<label class="col-form-label">Desk</label>
							</div>
							<div class="col-auto">
								<input type="text" class="form-control" style="max-width: 90px !important;"
									formControlName="traderSwap">
							</div>
						</div>
					</div>
					<div class="col-auto">
						<div class="row align-items-center">
							<div class="col-auto">
								<label class="col-form-label">Contract</label>
							</div>
							<div class="col-auto">
								<input type="text" formControlName="contract" style="max-width: 90px !important;"
									class="form-control">
							</div>
						</div>
					</div>
					<div class="col-auto">
						<div class="row align-items-center">
							<div class="col-auto">
								<label class="col-form-label">Original Client Order</label>
							</div>
							<div class="col-auto">
								<p-inputMask mask="{{dateTimeService.DateTimeMask}}"
									placeholder="{{dateTimeService.DateTimeMaskPlaceholder}}"
									styleClass="table-date-field-with-time"
									(onBlur)="setOrderTimes(orderDetailForm.get('orderTime')?.value)"
									slotChar="{{dateTimeService.DateTimeMaskPlaceholder}}" [showClear]="false"
									formControlName="orderTime" />
							</div>
						</div>
					</div>
					<div class="col-5">
						<div class="d-flex justify-content-end">
							<span *ngFor="let button of orderButtonService.buttons">
								<button pTooltip="{{button.title??button.caption}}" *ngIf="orderDetailForm.get('lifeCycleStatus')?.value === button.lifeCycleStatus 
								&& button.show" class="btn {{button.class}}" tooltipPosition="bottom"
									(click)="buttonEvents(button, orderDetailForm.get('orderNo')?.value, $event)">
									<i class="pi pi-ellipsis-v"></i> <i class="pi {{button.icon}}"></i>
									{{button.caption}} </button>
							</span>
							<span>
								<span>&nbsp;</span>
								<span>&nbsp;</span>
								<button class="ml-2 btn btn-light border" pTooltip="Delete Order"
									tooltipPosition="bottom"
									*ngIf="orderDetailForm.get('lifeCycleStatus')?.value !== 'LIVE'"
									(click)="deleteOverLay.toggle($event)">
									<i class="pi pi-trash"></i>
								</button>
								<button class="ml-2 btn btn-light border" pTooltip="Copy Order" tooltipPosition="bottom"
									(click)="copyOrder(orderDetailForm.get('orderNo')?.value)">
									<i class="pi pi-copy"></i>
								</button>
								<button class="ml-2 btn btn-light border" pTooltip="Reload Order"
									(click)="reloadOrder(orderDetailForm.get('orderNo')?.value)"
									tooltipPosition="bottom">
									<i class="pi pi-refresh"></i>
								</button>
							</span>

							<p-overlayPanel #confirmOverlayPanel [style]="{ width: '450px' }" [showCloseIcon]="false">
								<ng-template pTemplate="content">
									<div class="alert alert-info">
										Are you sure you want to mark this order <strong>Completed</strong>?
									</div>
									<div class="row">
										<div class="d-flex justify-content-end">
											<button class="btn btn-sm btn-primary"
												(click)="confirmOrder(orderDetailForm.get('orderNo')?.value)">
												Yes
											</button>
										</div>
									</div>
								</ng-template>
							</p-overlayPanel>
							<p-overlayPanel #deleteOverLay [style]="{ width: '450px' }" [showCloseIcon]="false">
								<ng-template pTemplate="content">
									<div class="alert alert-danger">
										Are you sure you want to delete this order? This action is irreversible.
									</div>
									<div class="row">
										<div class="d-flex justify-content-end">
											<button class="btn btn-sm btn-danger"
												(click)="deleteOrder(orderDetailForm.get('orderNo')?.value)">
												Yes
											</button>
										</div>
									</div>
								</ng-template>
							</p-overlayPanel>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-body">

			<div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="showErrorMessages">
				<div class="d-flex justify-content-end">
					<button type="button" class="btn" (click)="closeErrorMessage()" data-dismiss=" alert"
						aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>

				</div>
				<ul *ngIf="_errorMessages.length > 0">
					<li *ngFor="let errorMessage of _errorMessages">
						{{errorMessage}}
					</li>
				</ul>
			</div>
			<ng-container>
				<div class="row">
					<div class="col-12">
						<table class="table  table-bordered">
							<thead>
								<tr>
									<!-- <th>
										Id
									</th> -->
									<th>Audit</th>
									<th>Option Id</th>
									<th class="table-date-field">Maturity</th>
									<th class="orderType">Order Type</th>
									<th class="single-button">B/S</th>
									<th class="single-button">C/P</th>
									<th class="single-character-two-buttons">O/C</th>
									<th class="table-number-field">Qty</th>
									<th class="table-number-field">Strike</th>
									<th class="table-number-field">Price</th>
									<th class="table-number-field">Leaves Qty</th>
									<th class="table-date-field-with-time">Original Broker Instruction</th>
									<th class="table-date-field-with-time">Client Amend Requested</th>
									<th class="table-date-field-with-time">Client Amend Ack</th>
									<th class="table-date-field-with-time">Amendment Sent to Broker</th>
									<!-- <th>Sys Modified On</th> -->
								</tr>
							</thead>
							<tbody formArrayName="trades">

								<tr *ngFor="let trade of tradesForm.controls; let i = index" [formGroupName]="i">
									<!-- <td>
										{{trade.get('id')?.value}}
									</td> -->
									<td>
										<button class="btn btn-link"
											(click)="show(trade.get('id')?.value)">Audit</button>
									</td>
									<td class="table-date-field">
										<span
											style="font-size: 10px !important;">{{trade.get('optionId')?.value}}</span>
									</td>
									<td class="table-date-field">
										<p-calendar formControlName="maturityDate" dateFormat="yy-mm-dd"
											dataType="string"
											[style]="{'max-width': '50px !important;', 'min-width': '50px !important;'}"
											[readonlyInput]="true" appendTo="body" [showIcon]="true"></p-calendar>
									</td>
									<td class="orderType">
										<p-selectButton [options]="lookupService.orderTypes" formControlName="orderType"
											(onChange)="setOrders($event, orderDetailForm.get('orderNo')?.value, trade.get('orderType')?.value, 'orderType')"
											[multiple]="false" optionLabel="label" optionValue="value" />
									</td>
									<td class="single-button">
										<button class="btn btn-sm" [ngClass]="{'btn-success': trade.get('side')?.value == 'B',
																		'btn-danger': trade.get('side')?.value == 'S'}">
											{{trade.get('side')?.value}}
										</button>
									</td>
									<td class="single-button">
										<button class="btn btn-sm" [ngClass]="{
											'btn-success': trade.get('callPut')?.value == 'C',
											'btn-danger': trade.get('callPut')?.value == 'P'}">{{trade.get('callPut')?.value}}</button>
									</td>
									<td class="single-character-two-buttons">
										<p-selectButton [options]="lookupService.openClose"
											formControlName="openCloseIndicator" [multiple]="false" optionLabel="label"
											optionValue="value" />
									</td>
									<td class="table-number-field">
										<!-- <p-inputNumber formControlName="qty" mode="decimal" [size]="5"
											[minFractionDigits]="0" [maxFractionDigits]="0">
										</p-inputNumber> -->
										<input type="number" formControlName="qty" class="form-control" name="qty"
											step="1" min="1" [style]="{'max-width': '70px !important;'}" />
									</td>
									<td class="table-number-field">
										<!-- <p-inputNumber formControlName="strike" mode="decimal" [size]="6"
											[minFractionDigits]="0" [maxFractionDigits]="4">
										</p-inputNumber> -->
										<input type="number" formControlName="strike" class="form-control" name="strike"
											step="0.01" [style]="{'max-width': '70px !important;'}" />
									</td>
									<td class="table-number-field">
										<!-- <p-inputNumber formControlName="price" mode="decimal" [size]="6"
											*ngIf="trade.get('orderType')?.value !== 'MKT'" [minFractionDigits]="0"
											[maxFractionDigits]="4">
										</p-inputNumber> -->
										<input type="number" *ngIf="trade.get('orderType')?.value !== 'MKT'"
											formControlName="price" class="form-control" name="price" step=".01"
											[style]="{'max-width': '70px !important;'}" />
									</td>
									<td class="table-number-field">
										<p-inputNumber formControlName="leavesQty" mode="decimal" [size]="6"
											(onFocus)="leaveQtyOP.toggle($event)" [minFractionDigits]="0"
											[maxFractionDigits]="4">
										</p-inputNumber>
									</td>
									<td>
										<p-inputMask mask="{{dateTimeService.DateTimeMask}}"
											placeholder="{{dateTimeService.DateTimeMaskPlaceholder}}"
											slotChar="{{dateTimeService.DateTimeMaskPlaceholder}}" [showClear]="false"
											styleClass="table-date-field-with-time"
											(onKeydown)="validateTimes(trade.get('id')?.value, 'routeTime', trade.get('orderTime')?.value)"
											formControlName="routeTime" />
									</td>
									<td class="table-date-field-with-time">

										<div class="input-group" *ngIf="showModifyButtons">
											<p-inputMask mask="{{dateTimeService.DateTimeMask}}"
												placeholder="{{dateTimeService.DateTimeMaskPlaceholder}}"
												styleClass="table-date-field-with-time"
												slotChar="{{dateTimeService.DateTimeMaskPlaceholder}}"
												[showClear]="false" formControlName="modifyRequestedOn" />
											<input type="checkbox" tabindex="-1"
												(change)="setCurrentTimes($event, 'modifyRequestedOn', trade.get('id')?.value)">
										</div>

									</td>
									<td class="table-date-field-with-time" *ngIf="showModifyButtons">
										<div class="input-group" *ngIf="showModifyButtons">
											<p-inputMask mask="{{dateTimeService.DateTimeMask}}"
												placeholder="{{dateTimeService.DateTimeMaskPlaceholder}}"
												styleClass="table-date-field-with-time"
												slotChar="{{dateTimeService.DateTimeMaskPlaceholder}}"
												[showClear]="false" formControlName="clientModificationAckOn" />
											<input type="checkbox" tabindex="-1"
												(change)="setCurrentTimes($event, 'clientModificationAckOn', trade.get('id')?.value)">
										</div>
									</td>
									<td class="table-date-field-with-time">
										<div class="input-group" *ngIf="showModifyButtons">
											<p-inputMask mask="{{dateTimeService.DateTimeMask}}"
												placeholder="{{dateTimeService.DateTimeMaskPlaceholder}}"
												styleClass="table-date-field-with-time"
												slotChar="{{dateTimeService.DateTimeMaskPlaceholder}}"
												[showClear]="false" formControlName="modifyAckOn" />
											<input type="checkbox" tabindex="-1"
												(change)="setCurrentTimes($event, 'modifyAckOn', trade.get('id')?.value)">
										</div>
									</td>
								</tr>
								<tr *ngIf="showCancel" class="animate__fadeInDown">
									<td colspan="16">
										<cancel-order [orderDetailForm]="orderDetailForm" [tradesForm]="tradesForm"
											[formGroup]="_formGroup" (onExecute)="cancelOrder($event)"
											(OnCancel)="onCancelOrderClick()">
										</cancel-order>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>


<p-overlayPanel #leaveQtyOP class="bg-warning text-dark">
	<div class="d-flex" style="max-width: 250px !important;">
		<ul>
			<li>
				For the amendment, if there are no fills, and the quantity is unchanged by
				the client, then the leaves quantity should be equal to the quantity.
			</li>
			<li>
				If there are partial fills received from Velocity
				(assuming that’s possible), then the leaves
				quantity would be the order quantity minus the cumulative filled quantity.
			</li>

		</ul>

	</div>
</p-overlayPanel>