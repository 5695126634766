import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import * as moment from 'moment';
import { UserDetails } from '../model/user-detail.model';
import { SharedService } from '../services/shared.service';
import { TradeModel, TradeModelGroup } from '../model/trade.model';
import { DateTimeConstantService } from '../constants/date-time.constant.service';
import { LookupsService } from '../shared/lookups.service.component';
import { customDateValidator } from '../shared/validators/date-validators.function';
import { BlotterService } from '../services/blotter.service';
import { ReferenceDataService } from '../services/reference-data.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuditListComponent } from '../audit-list/audit-list..component';
import { OrderButtonStatusModel, OrderButtonStatusService } from '../model/order-button.model';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'blotter-form',
    templateUrl: './blotter-form.component.html',
    styles: [`
	.table-dark {
    	background-color: #e0e2e4 !important;
    }
	`],
    providers: [
        OrderButtonStatusService
    ]
})
export class BlotterFormComponent implements OnInit, AfterViewInit {

    private _fb: FormBuilder;
    public _formGroup!: FormGroup;

    @Input()
    public order!: TradeModelGroup;

    @Output()
    public onOrderCancelled = new EventEmitter<any>();

    public minDate!: Date;
    public minDateWithTime!: Date;
    public maxDateWithTime!: Date;
    public DB_FORMAT_TIME = "YYYY-MM-DD";
    public showCancel: boolean = false;
    public _errorMessages: string[] = [];
    public accounts: any[] = [];
    public hasDataChanged: boolean = false;
    private _currentOrderNo!: string;
    private _dbOrderTime!: Date;
    public dateTimemask: string = "9999-99-99T99:99:99.999";
    public dateTimePlaceholder: string = "yyyy-MM-ddTHH:mm:ss.SSS";
    public showModifyButtons: boolean = false;
    private DATE_FORMAT_TO_MS = "YYYY-MM-DDTHH:mm:ss.SSS";
    public newTradesAdded: EventEmitter<any> = new EventEmitter<any>();
    public showMarkCompletedButton: boolean = true;
    dynamicDialogRef: DynamicDialogRef | undefined;
    public isSavingData: boolean = false;
    public showErrorMessages: boolean = false;
    @ViewChild('confirmOverlayPanel') confirmOverlayPanel!: OverlayPanel;

    constructor(
        fb: FormBuilder,
        private _sharedService: SharedService,
        private _dateTimeConstantService: DateTimeConstantService,
        private _blotterService: BlotterService,
        public lookupService: LookupsService,
        public referenceDataService: ReferenceDataService,
        public dialogService: DialogService,
        public orderButtonService: OrderButtonStatusService,
        public dateTimeService: DateTimeConstantService) {
        this._fb = fb;
    }

    ngAfterViewInit() {
        //this._sharedService.removeEmailPreviewOrders();
        this.minDate = new Date();
        this.minDateWithTime = moment().subtract(10, 'minute').toDate();
        this.minDateWithTime = moment().add(2, 'minute').toDate();

    }


    public buttonEvents(button: OrderButtonStatusModel, orderNo: string, $event: any) {

        if (button.tag == "RELOAD_ORDER") {
            this.loadOrders(orderNo);
            this.orderButtonService.buttons.forEach((button: any) => {
                if (button.tag == "CONFIRMED" || button.tag == "CANCEL_ORDER") {
                    button.show = true;
                }
            });
            this._errorMessages = [];
            return;
        }
        if (button.tag == "SAVE_DRAFT") {

            this.saveOrder(orderNo, button.lifeCycleStatus, false, false);
            return;
        }

        if (button.tag == "START_EVENTS") {
            this.saveOrder(orderNo, "LIVE", false, true);
            this.orderButtonService.buttons.forEach((button: any) => {
                if (button.tag == "CONFIRMED" || button.tag == "CANCEL_ORDER") {
                    button.show = true;
                }
            });
            this._errorMessages = [];
            this.orderButtonService.resetStatus();
            return;
        }

        if (button.tag == "SAVE") {
            this.saveOrder(orderNo, button.lifeCycleStatus);
            this.orderButtonService.resetStatus();
            this.orderButtonService.buttons.forEach((button: any) => {
                if (button.tag == "CONFIRMED" || button.tag == "CANCEL_ORDER") {
                    button.show = true;
                }
            });
            return;
        }

        if (button.tag == "CONFIRMED") {
            this.confirmOverlayPanel.show($event); //  .toggle($event.nativeElement);
            return;
        }

        if (button.tag == "CANCEL_ORDER") {
            this.showCancel = !this.showCancel;
            // this.orderButtonService.buttons.forEach((btn: OrderButtonStatusModel) => {
            //     btn.show = false;
            // })
            return;
        }

        if (button.tag == "CANCEL") {
            this.orderButtonService.resetStatus();

            return;
        }

    }

    ngOnInit(): void {
        this._formGroup = this._fb.group({
            orderDetail: this._fb.group({
                orderNo: new FormControl(null, [Validators.required]),
                traderSwap: new FormControl(null, [Validators.required]),
                orderTime: new FormControl(null, [Validators.required, customDateValidator("")]),
                contract: new FormControl(null, [Validators.required]),
                lifeCycleStatus: new FormControl(null, [Validators.required]),
                cancelRequestOn: new FormControl(null),
                cancelledOn: new FormControl(null),
                clientCancellationAckOn: new FormControl(null),
                endClient: new FormControl(null, [Validators.required]),
                leavesQty: new FormControl(null)
            }),
            trades: this._fb.array([])
        });

        this.populateData();
    }

    populateData() {
        if (this.order) {
            this.showModifyButtons = false;
            this._currentOrderNo = this.order.orderNo;
            this._dbOrderTime = this.order.orderTime;

            //console.log(this.order);
            if (this.order.blotters.length > 0) {
                this._formGroup.get('orderDetail.orderTime')?.setValue(this.order.orderTime);
                this._formGroup.get('orderDetail.orderNo')?.setValue(this.order.orderNo);
                this._formGroup.get('orderDetail.orderNo')?.disable()
                this._formGroup.get('orderDetail.traderSwap')?.setValue(this.order.traderSwap);
                this._formGroup.get('orderDetail.traderSwap')?.disable();
                this._formGroup.get('orderDetail.contract')?.setValue(this.order.contract);
                this._formGroup.get('orderDetail.contract')?.disable();
                this._formGroup.get('orderDetail.lifeCycleStatus')?.setValue(this.order.lifeCycleStatus);
                this._formGroup.get('orderDetail.cancelRequestOn')?.setValue(this.order.blotters[0].cancelRequestOn);
                this._formGroup.get('orderDetail.cancelledOn')?.setValue(this.order.blotters[0].cancelledOn);
                this._formGroup.get('orderDetail.endClient')?.setValue(this.order.endClient);
                this._formGroup.get('orderDetail.leavesQty')?.setValue(this.order.leavesQty);

                this.tradesForm.clear();
                this.order.blotters.forEach((trade: TradeModel) => {
                    this.addOrder(trade);
                });

                this.orderDetailForm.get('endClient')?.disable();
                if (this.orderDetailForm.get('lifeCycleStatus')?.value == "LIVE") {
                    this.orderDetailForm.get('orderTime')?.disable();
                } else {
                    this.orderDetailForm.get('orderTime')?.enable();
                }
                this.orderDetailForm.updateValueAndValidity();

                //this.disableFieldsWhenTradeIsLive();
            }
        }
    }

    isGreaterThanCurrentTime(dateTime: string): boolean {
        return moment(dateTime).isAfter(moment());
    }

    get tradesForm(): FormArray {
        const array = <FormArray>this._formGroup.get('trades')
        return array;
    }

    get orderDetailForm(): FormGroup {
        const array = <FormGroup>this._formGroup.get('orderDetail')
        return array;
    }

    public reloadOrder(orderNo: string) {
        this.loadOrders(orderNo);
        this.orderButtonService.buttons.forEach((button: any) => {
            if (button.tag == "CONFIRMED" || button.tag == "CANCEL_ORDER") {
                button.show = true;
            }
        });
        this._errorMessages = [];
        this.showErrorMessages = false;
        return;
    }

    public disableFieldsWhenTradeIsLive(tradeForm: FormGroup) {

        if (this.orderDetailForm.get('lifeCycleStatus')?.value == "LIVE") {

            tradeForm.get('routeTime')?.disable();
            tradeForm.get('side')?.disable();
            tradeForm.get('callPut')?.disable();
            tradeForm.get('openCloseIndicator')?.disable();
            //tradeForm.get('orderType')?.disable();
            tradeForm.get('maturityDate')?.disable();

        } else {
            tradeForm.get('routeTime')?.enable();
            tradeForm.get('side')?.enable();
            tradeForm.get('callPut')?.enable();
            tradeForm.get('openCloseIndicator')?.enable();
            //tradeForm.get('orderType')?.enable();
            tradeForm.get('maturityDate')?.enable();
        }
    }

    private showHideButtons(buttonTag: string, show: boolean) {

        this.orderButtonService.buttons.forEach((response: OrderButtonStatusModel) => {
            if (response.tag == buttonTag) {
                response.show = show;
            }
        });
    }

    public addOrder(trade: TradeModel) {

        // if (trade.modifyRequestedOn != null) {
        //     this.showModifyButtons = true;
        // }

        this.showModifyButtons = false;
        this.showHideButtons("SAVE", false);


        const orderForm = this._fb.group({
            id: new FormControl(trade.id, [Validators.required]),
            orderNo: new FormControl(trade.orderNo, [Validators.required]),
            side: new FormControl(trade.side, [Validators.required]),
            qty: new FormControl(trade.qty, [Validators.required]),
            contract: new FormControl(trade.contract, [Validators.required]),
            maturityDate: new FormControl(moment(trade.maturityDate).toDate(), [Validators.required]),
            strike: new FormControl(trade.strike, [Validators.required]),
            price: new FormControl(trade.price),
            leavesQty: new FormControl(trade.leavesQty),
            traderSwap: new FormControl(trade.traderSwap, [Validators.required]),
            orderTime: new FormControl(trade.orderTime, [Validators.required]),
            routeTime: new FormControl(trade.routeTime, [Validators.required, customDateValidator("orderTime")]),
            isConfirmed: new FormControl(trade.isConfirmed),
            confirmedOn: new FormControl(trade.confirmedOn),
            isDraft: new FormControl(trade.isDraft, [Validators.required]),
            isSelected: new FormControl(trade.isSelected, [Validators.required]),
            modifiedOn: new FormControl(trade.modifiedOn),
            futureOrOption: new FormControl(trade.futureOrOption),
            totalRows: new FormControl(trade.totalRows),
            callPut: new FormControl(trade.callPut, [Validators.required]),
            rowVersion: new FormControl(trade.rowVersion, [Validators.required]),
            orderStatus: new FormControl(trade.orderStatus, [Validators.required]),
            username: new FormControl(this._sharedService.getUserDetails.username, [Validators.required]),
            strategyUsed: new FormControl(trade.strategyUsed, [Validators.required]),
            isMultiLeg: new FormControl(trade.isMultiLeg, [Validators.required]),
            orderType: new FormControl(trade.orderType, [Validators.required]),
            lifeCycleStatus: new FormControl(trade.lifeCycleStatus, [Validators.required]),
            openCloseIndicator: new FormControl(trade.openCloseIndicator, [Validators.required]),
            modifyRequestedOn: new FormControl(trade.modifyRequestedOn, null),
            modifyAckOn: new FormControl(trade.modifyAckOn, null),
            cancelRequestOn: new FormControl(trade.cancelRequestOn, null),
            cancelledOn: new FormControl(trade.cancelledOn, null),
            clientModificationAckOn: new FormControl(trade.clientModificationAckOn, null),
            clientCancellationAckOn: new FormControl(trade.clientCancellationAckOn, null),
            isCancelled: new FormControl(trade.isCancelled),
            optionId: new FormControl(trade.optionId),
            endClient: new FormControl(trade.endClient, [Validators.required]),
            optionKind: new FormControl(trade.optionKind, [Validators.required]),
            settlementType: new FormControl(trade.settlementType, [Validators.required]),
            exerciseStyle: new FormControl(trade.exerciseStyle, [Validators.required])
        });

        const editableControls: any[] = ["side", "openCloseIndicator", "orderType", "callPut", "routeTime", "orderTime", "maturityDate", "strike", "price", "qty"];
        this.disableFieldsWhenTradeIsLive(orderForm);

        this.setChangeEventsForTradesForm(orderForm, trade, editableControls);

        if (orderForm.get('orderType')?.value == "MKT") {
            orderForm.get('price')?.setValidators(null);
        } else {
            orderForm.get('price')?.setValidators(Validators.required);
        }

        this.tradesForm.push(orderForm);
    }

    public isDateInPast(orderTime: string): boolean {
        return moment(orderTime).isBefore(moment(), 'd');
    }

    setChangeEventsForTradesForm(tradeForm: FormGroup, trade: any, fieldNames: string[]) {
        fieldNames.forEach((fieldName: string) => {
            tradeForm.get(fieldName)?.valueChanges.subscribe(response => {
                if (trade[fieldName] !== response) {
                    this.showModifyButtons = this.orderDetailForm.get('lifeCycleStatus')?.value == "LIVE";
                    this.showMarkCompletedButton = false;
                    if (this.orderDetailForm.get('lifeCycleStatus')?.value == "LIVE") {
                        tradeForm.get('clientModificationAckOn')?.setValidators([Validators.required]);
                        tradeForm.get('clientModificationAckOn')?.setValue(null);
                        tradeForm.get('modifyRequestedOn')?.setValidators([Validators.required]);
                        tradeForm.get('modifyRequestedOn')?.setValue(null);
                        tradeForm.get('modifyAckOn')?.setValidators([Validators.required]);
                        tradeForm.get('modifyAckOn')?.setValue(null);
                        tradeForm.get('leavesQty')?.setValidators([Validators.required]);
                        tradeForm.get('leavesQty')?.setValue(null);
                        tradeForm.updateValueAndValidity();

                        this.showHideButtons("SAVE", true);
                    }

                    this.orderButtonService.buttons.forEach((button: any) => {
                        //debugger;
                        if (button.tag == "CONFIRMED" || button.tag == "CANCEL_ORDER") {
                            button.show = this.showMarkCompletedButton;
                        }
                    });

                } else {
                    this.showModifyButtons = false;
                    this.showMarkCompletedButton = true;
                    tradeForm.get('clientModificationAckOn')?.setValidators(null);
                    tradeForm.get('modifyRequestedOn')?.setValidators(null);
                    tradeForm.get('modifyAckOn')?.setValidators(null);
                    tradeForm.get('leavesQty')?.setValidators(null);
                    tradeForm.updateValueAndValidity();

                    this.orderButtonService.buttons.forEach((button: any) => {
                        //debugger;
                        if (button.tag == "CONFIRMED" || button.tag == "CANCEL_ORDER") {
                            button.show = this.showMarkCompletedButton;
                        }
                    });

                    this.showHideButtons("SAVE", false);
                }
            });
        });
    }

    public isDateValid(dateTime: string) {
        const momentDate = moment(dateTime);
        if (momentDate.isValid()) {
            return true;
        }

        return false;
    }

    public isGreaterThanCurrenTime(dateTime: string) {
        if (this.isDateValid(dateTime)) {
            if (moment(dateTime).isAfter(moment(), 'day')) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    public setCurrentTimes($event: any, fieldName: string, id: number) {
        var ctl = this.tradesForm.controls.filter(d => d.get('id')?.value == id)[0];
        if ($event.target.checked) {
            ctl.get(fieldName)?.setValue(moment().format(this.DATE_FORMAT_TO_MS));
        } else {
            ctl.get(fieldName)?.setValue(null);
        }


        ctl.updateValueAndValidity();
    }

    //validate trade 
    setOrderTimes(orderTime: string) {
        //check if time is valid
        if (this.isDateValid(orderTime)) {
            this.tradesForm.controls.filter(d => d.get('orderNo')?.value == this._currentOrderNo)
                .forEach((trade: any) => {
                    trade.get('orderTime').setValue(orderTime);
                    trade.get('orderTime').setErrors(null);
                });

            this.orderDetailForm.get('orderTime')?.setErrors(null);
        } else {
            this.tradesForm.controls.filter(d => d.get('orderNo')?.value == this._currentOrderNo)
                .forEach((trade: any) => {
                    trade.get('orderTime').setErrors({ "invalidDate": "Date is invalid" });
                });
            this.orderDetailForm.get('orderTime')?.setErrors({ "invalidDate": "Date is invalid" })
        }
    }

    validateTimes(id: number, fieldName: string, dateToCompare: string) {

        var control = this.tradesForm.controls.filter(d => d.get('id')?.value == id)[0].get(fieldName);

        if (moment(control?.value).isBefore(moment(dateToCompare))) {
            control?.setErrors({ "wrong date": `Date should be >= ${dateToCompare}` });
        } else {
            control?.setErrors(null);
        }
    }

    setOrders($event: any, orderNo: string, value: any, field: any) {
        this.tradesForm.controls.filter(d => d.get('orderNo')?.value == orderNo).forEach((ctl: any) => {
            ctl.get(field).setValue(value);
            console.log(ctl.get(field).value);
        });
    }

    get getUserDetails(): UserDetails {
        const userDetail: any = localStorage.getItem('userDetails');
        return JSON.parse(userDetail);
    }

    public getUtcDateTime(dateTime: string | null): string {
        return this._dateTimeConstantService.getUtcTime(dateTime != null ? dateTime : moment().format(this._dateTimeConstantService.DbDateTimeFormat));
    }

    public onCancelOrderClick() {
        this.showCancel = !this.showCancel;
        if (!this.showCancel)
            this.orderButtonService.resetStatus();

        this.tradesForm.get('modifyRequestedOn')?.setValue(null);
        this.tradesForm.get('clientModificationAckOn')?.setValue(null);
        this.tradesForm.get('modifyAckOn')?.setValue(null);
        this.showHideButtons("SAVE", false);
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.tradesForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
    }

    public cancelOrder(orderNo: string) {
        //this.saveOrder($event[0].orderNo, 'LIVE', true);
        this.onOrderCancelled.emit(orderNo);
    }

    public confirmOrder(orderNo: string) {
        this.tradesForm.controls.filter(d => d.get('orderNo')?.value == orderNo)
            .forEach((trade: AbstractControl<any, any>) => {
                trade.get('isConfirmed')?.setValue(true);
            });

        this.saveOrder(orderNo, 'LIVE', true);
    }


    private GetTradesAsRawValue(orderNo: string, lifeCycleStatus: string): TradeModel[] {
        const trades: TradeModel[] = [];
        this.tradesForm.controls.filter(d => d.get('orderNo')?.value == orderNo)
            .forEach((trade: AbstractControl<any, any>) => {
                trade.get('lifeCycleStatus')?.setValue(lifeCycleStatus);
                const maturityDate = moment(trade.get('maturityDate')?.value).format("YYYY-MM-DD");

                var tradeRawValue = trade.getRawValue() as TradeModel;
                tradeRawValue.maturityDate = maturityDate;
                trades.push(tradeRawValue);

            });

        return trades;

    }

    private ResetTradeFlags(orderNo: string, lifeCycleStatus: string) {
        const trades: TradeModel[] = [];
        this.tradesForm.controls.filter(d => d.get('orderNo')?.value == orderNo)
            .forEach((trade: AbstractControl<any, any>) => {
                trade.get('lifeCycleStatus')?.setValue(lifeCycleStatus);
            });

        return trades;

    }

    public saveAndStartEvents(orderNo: string) {
        this.saveOrder(orderNo, "LIVE", false, true);
    }

    private loadOrders(orderNo: string) {
        this._blotterService.getBlottersByOrderNo(orderNo)
            .subscribe((response: TradeModelGroup) => {
                this.order = response;
                this.populateData();
            })
    }

    public saveOrder(orderNo: string, lifeCycleStatus: string, isCancelOrder: boolean = false, startEvents: boolean = false) {

        this.isSavingData = false;
        const trades = this.GetTradesAsRawValue(orderNo, lifeCycleStatus);

        //we will run the validation (sync operation)
        this._blotterService.saveBlottersAndReturnAsGroup(trades, this._sharedService.getUserDetails.username, startEvents)
            .subscribe({
                next: (response: TradeModelGroup[]) => {
                    //do nothing, for now
                    this._errorMessages = [];
                    this.showErrorMessages = this._errorMessages.length > 0;
                    this.order = response[0];
                    this.isSavingData = false;

                    if (isCancelOrder) {
                        this.onOrderCancelled.emit(orderNo);
                    } else {
                        this.loadOrders(orderNo);
                        // this._blotterService.getBlottersByOrderNo(orderNo)
                        //     .subscribe((response: TradeModelGroup) => {
                        //         this.order = response;
                        //         this.populateData();
                        //     })
                    }
                    this.showModifyButtons = false;
                    this.showMarkCompletedButton = true;

                },
                error: (err: any) => {
                    this._errorMessages = [];
                    //this.showErrorMessages = this._errorMessages.length > 0;
                    if (err.status == 417) {
                        var message = err.error;
                        this.ResetTradeFlags(orderNo, lifeCycleStatus == "LIVE" ? "PENDING" : "LIVE");
                        if (Array.isArray(err.error)) {
                            err.error.forEach((response: any) => {

                                this._errorMessages.push(response);
                            })
                            // dialog.messages = err.error;
                        } else {
                            this._errorMessages.push(err.error);
                        }

                        console.log(this._errorMessages);
                    }
                    this.showErrorMessages = this._errorMessages.length > 0;
                    this.isSavingData = false;

                },
                complete: () => {
                    this.isSavingData = false;
                }
            })
    }

    deleteOrder(orderNo: string) {
        this._blotterService.delete(orderNo, this._sharedService.getUserDetails.username)
            .subscribe({
                next: (response) => {
                    this.onOrderCancelled.emit(orderNo);
                },
                error: (err) => {
                    var message = err.error;
                    this._errorMessages.push(message);
                    this.showErrorMessages = this._errorMessages.length > 0;
                },
                complete: () => { }
            })
    }

    show(id: number) {
        console.log(id);
        this.dynamicDialogRef = this.dialogService.open(AuditListComponent, {
            header: 'Audit list',
            width: '100%',
            contentStyle: { overflow: 'auto' },
            baseZIndex: 10000,
            data: {
                blotterId: id
            },
            maximizable: true
        });

    }

    closeErrorMessage() {
        this.showErrorMessages = false;
        this._errorMessages = [];
    }

    copyOrder(orderNo: string) {
        this._blotterService.copyOrder(orderNo, this._sharedService.getUserDetails.username)
            .subscribe((response: any) => {
                this.onOrderCancelled.emit(orderNo);
                alert(`New generated order No: ${response}`);
            })
    }

}
