import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormArray, FormGroup, Validators } from "@angular/forms";
import { DateTimeConstantService } from "../../constants/date-time.constant.service";

import * as moment from "moment";
import { OrderEventService } from "../../services/order-event.service";

@Component({
    selector: 'modify-order',
    templateUrl: './modify-order.component.html',
    providers: [
        OrderEventService
    ]
})
export class ModifyOrderComponent {

    @Input()
    formGroup!: FormGroup;
    @Input()
    tradesForm!: FormGroup;
    @Output() onExecute = new EventEmitter<string>();
    @Output() OnCancel = new EventEmitter<any>();
    public _errorMessages: string[] = [];

    constructor(public dateTimeService: DateTimeConstantService,
        private cancelOrderService: OrderEventService
    ) { }

    public modifyOrder(orderNo: string) {

        this.cancelOrderService.modify(this.tradesForm.get('id')?.value,
            this.tradesForm.get('modifyRequestedOn')?.value,
            this.tradesForm.get('clientModificationAckOn')?.value,
            this.tradesForm.get('modifyAckOn')?.value,
            this.tradesForm.get('routeTime')?.value,
            this.tradesForm.get('leavesQty')?.value)
            .subscribe({
                next: (response: any) => {
                    this.onExecute.emit(orderNo);
                },
                error: (err) => {
                    this._errorMessages = [];
                    if (err.status == 417) {
                        var message = err.error;
                        if (Array.isArray(err.error)) {
                            err.error.forEach((response: any) => {
                                this._errorMessages.push(response);
                            })
                            // dialog.messages = err.error;
                        } else {
                            this._errorMessages.push(err.error);
                        }

                        console.log(this._errorMessages);
                    } else {
                        this._errorMessages.push(message);
                    }
                },
                complete: () => { }
            })


    }

    public setAllToCurrentTime() {
        const currentTime = moment().format(this.dateTimeService.DbDateTimeFormat);
        this.tradesForm.get('modifyRequestedOn')?.setValue(currentTime);
        this.tradesForm.get('clientModificationAckOn')?.setValue(currentTime);
        this.tradesForm.get('modifyAckOn')?.setValue(currentTime);
    }

    public exit() {
        this.tradesForm.get('modifyRequestedOn')?.setValidators(null)
        this.tradesForm.get('modifyAckOn')?.setValidators(null)
        this.tradesForm.get('leavesQty')?.setValidators(null)
        this.tradesForm.get('clientModificationAckOn')?.setValidators(null)
        this.tradesForm.updateValueAndValidity();
        this.OnCancel.emit();
    }
}