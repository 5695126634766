<div class="container-fluid" style="max-width: 100% !important;">
  <top-navigation *ngIf="loginDisplay" [loginDisplay]="loginDisplay" [username]="username" (loginEvent)="login()"
    (logoutEvent)="logout()"></top-navigation>

  <div *ngIf="!loginDisplay">
    <div class="card d-flex align-items-center justify-content-center">
      <img src="../assets/DraxPrime-Logo-USEQ.png" />
      <div class="card-body">
        <p class="card-text">&nbsp;</p>
        <p class="card-text">&nbsp;</p>
        <p class="card-text">&nbsp;</p>
        <p class="card-text">&nbsp;</p>
        <p class="card-text">&nbsp;</p>
        <a href="#" class="btn btn-lg btn-primary" (click)="login()">Login</a>
      </div>
    </div>
  </div>
</div>

<div style="max-width: 100% !important;">
  <router-outlet></router-outlet>
</div>