import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'numeric-input',
    templateUrl: './numeric-input.component.html'
})
export class NumericInputComponent implements OnInit {


    @Input()
    public value!: number;

    private originalValue!: number;

    public hasValueChanged: boolean = false;
    @Output() onBlur = new EventEmitter<number>();

    onValueChange() {
        this.hasValueChanged = this.originalValue !== this.value;
        this.onBlur.emit(this.value);
    }

    ngOnInit(): void {
        this.originalValue = this.value;
    }
}