import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[numeric]'
})

export class NumericDirective {

	private specialKeys = [
		'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
	];

	private hasFocus = false;

	@Input() decimals = 0;

	private check(value: string, decimals: number) {
		if (decimals <= 0) {
			return String(value).match(new RegExp(/^\d+$/));
		} else {
			const regExpString = '^\\s*((\\d+(\\.\\d{0,' + decimals + '})?)|((\\d*(\\.\\d{1,' + decimals + '}))))\\s*$'
			return String(value).match(new RegExp(regExpString));
		}
	}

	constructor(private el: ElementRef) {

	}

	@HostListener('change', ['$event'])
	onChange(event: KeyboardEvent) {
		if (!this.hasFocus) {
			const value = this.el.nativeElement.value;
			if (value) {
				this.el.nativeElement.value = 
					new Intl.NumberFormat('en-GB', {maximumFractionDigits: this.decimals}).format(this.el.nativeElement.value);
			}
		}
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {

		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}
		const current: string = this.el.nativeElement.value;
		const next: string = current.concat(event.key);
		if (next && !this.check(next, this.decimals)) {
			event.preventDefault();
		}


	}

	@HostListener('blur', ['$event'])
	onBlur(event: KeyboardEvent) {

		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}

		this.hasFocus = false;
		const value = this.el.nativeElement.value;
		if (value) {
			if (this.decimals <= 1) {
				this.el.nativeElement.value = new Intl.NumberFormat('en-GB').format(value);
			} else {
				this.el.nativeElement.value = new Intl.NumberFormat('en-GB', {maximumFractionDigits: this.decimals}).format(value);
			}
		}

	}

	@HostListener('focus', ['$event'])
	onFocus(event: KeyboardEvent) {
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}
		this.hasFocus = true;
		const re = /,/gi;
		const value = this.el.nativeElement.value;
		this.el.nativeElement.value = value.toString().trim().replace(re, '');
		this.el.nativeElement.select();
	}
}
