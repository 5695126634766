import { Component, Input, OnInit } from '@angular/core';
import { TradeModel } from '../model/trade.model';
import { BlotterService } from '../services/blotter.service';

@Component({
	selector: 'price-summary',
	templateUrl: './price-summary.component.html',
	styleUrls: ['./price-summary.component.css']
})
export class PriceSummaryComponent implements OnInit {

	public priceSummary: any = {
		price: 0,
		volume: 0
	};
	public showComponent: boolean = false;
	@Input() status: string = "DRAFT";
	@Input() trades: TradeModel[] = [];
	constructor(private _blotterService: BlotterService) { }

	ngOnInit(): void {
		this.refresh();
	}

	public refresh() {
		let volume = 0;
		let price = 0;
		this.priceSummary = {
			volume: volume,
			price: price
		};

		this.trades.forEach((trade: TradeModel) => {

			volume += trade.qty == null ? 0 : trade.qty;
			if (trade.side === "B") {
				price += trade.price == null ? 0 : trade.price;
			} else {
				price -= trade.price == null ? 0 : trade.price;
			}
		});

		this.priceSummary.volume = volume;
		this.priceSummary.price = price;
		this.showComponent = true;
		// this._blotterService.getSummary(this.status)
		// 	.subscribe((response: any) => {
		// 		this.priceSummary = response;
		// 		this.showComponent = true;
		// 	});
	}

}
