import { MsalInterceptorConfiguration } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";
import { ConfigurationService } from "../services/config.service";

// provides authRequest configuration
export function MSALInterceptorConfigFactory(configService: ConfigurationService): MsalInterceptorConfiguration {

    const msal = configService.get('msal');
    const apiUrl = configService.get('apiUrl');
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
    protectedResourceMap.set(apiUrl, [msal.scope]);
    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap
    };
}