import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpXhrBackend } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    private _config: any;
    private _env!: any;
    private _configCustom: any;
    private http: HttpClient;
    private settings: any;
    constructor(private readonly httpHandler: HttpBackend) {
        this.http = new HttpClient(httpHandler);
    }

    public get configuration(): any { return this._config; }

    /* New way of initialisation. Old will be marked as Obsolete*/
    init(endpoint: string): Promise<boolean> {
        endpoint = '/assets/config/env.json';
        return new Promise<boolean>((resolve, reject) => {
            this.http.get(endpoint).pipe(map(res => res))
                .subscribe({
                    next: ((value: any) => {
                        this.settings = value;
                        resolve(true);
                    }),
                    error: ((error: any) => {
                        reject(error);
                    }),
                    complete: (() => { })
                });
        });
    }

    getSettings(key?: string | Array<string>): any {
        if (!key || (Array.isArray(key) && !key[0])) {
            return this.settings;
        }

        if (!Array.isArray(key)) {
            key = key.split('.');
        }

        let result = key.reduce((acc: any, current: string) => acc && acc[current], this.settings);

        return result;
    }

    //Obsolete
    public async load(): Promise<any> {

        // this is required because injecting HttpClient will lead to configuration not being available when MSAL is initialized
        // more info: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1403#issuecomment-672777048
        const httpClient = new HttpClient(new HttpXhrBackend({
            build: () => new XMLHttpRequest()
        }));

        this._env = await lastValueFrom(httpClient.get<any>('/assets/config/env.json'));

        const httpClient1 = new HttpClient(new HttpXhrBackend({
            build: () => new XMLHttpRequest()
        }));

        const config = await lastValueFrom(httpClient1.get<any>('/assets/config/' + this._env.env + '.json'));
        return this._config = config;
    }

    //Obsolete
    getEnv(key: any) {
        return this._env[key];
    }

    //Obsolete
    get(key: any) {
        //console.trace();
        //console.log(key, this._config);
        return this._config[key];
    }

    //Obsolete
    setCustom(key: any, val: any) {
        this._configCustom[key] = val;
    }

    //Obsolete
    getCustom(key: any) {
        return this._configCustom[key];
    }
}
