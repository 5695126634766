<div class="row">
    <div class="col-7">
        <p-scrollPanel [style]="{ width: '100%', height: '200px', float: 'left !important'}">
            <p-table [value]="leftValues" (onRowSelect)="onLeftRowSelect($event)" selectionMode="single"
                [paginator]="false" [(selection)]="selectedLeftValue" dataKey="key">
                <ng-template pTemplate="body" let-bracketTime>
                    <tr [pSelectableRow]="bracketTime">
                        <td>{{bracketTime.key}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-scrollPanel>
    </div>
    <div class="col-5">
        <p-scrollPanel [style]="{ width: '100%', height: '100px', float: 'left !important'}">
            <p-table [value]="rightValues" (onRowSelect)="onRightRowSelect($event)" selectionMode="single"
                [paginator]="false" [(selection)]="selectedRightValue" dataKey="key">
                <ng-template pTemplate="body" let-bracketTime>
                    <tr [pSelectableRow]="bracketTime">
                        <td>{{bracketTime.key}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-scrollPanel>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="d-flex flex-row-reverse">
            <button class="btn btn-outline-success" type="button" (click)="confirmFinalValue()">
                <i *ngIf="finalFormattedValue !== null" class="k-icon k-i-tick"></i> {{finalFormattedValue}}
            </button>
        </div>
    </div>
</div>