import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ReferenceDataService } from "../../services/reference-data.service";
import { StrategyRuleViewModel } from "../../model/strategy-rule.model";
import { SharedService } from "../../services/shared.service";
import { LookupsService } from "../../shared/lookups.service.component";
import { TradeModel } from "../../model/trade.model";
import { BlotterService } from "../../services/blotter.service";
import * as moment from "moment";

@Component({
    selector: 'add-blotter',
    templateUrl: './add-blotter-form.component.html'
})
export class AddBlotterComponent implements OnInit {

    public _formGroup!: FormGroup;
    public isDelta: boolean = false;
    public rules: StrategyRuleViewModel[] = [];
    public selectedRule!: any;
    public contracts: any[] = [];
    public selectedContract!: any;
    public traderSwaps: any = [];
    public minDate!: Date;
    public showPrice: boolean = true;
    public hasValidationError: boolean = false;
    public errorMessages: string[] = [];
    public contractStrikes: any[] = [];
    public accounts: any[] = [];

    @Output() onAdd = new EventEmitter<TradeModel>();

    constructor(private _fb: FormBuilder,
        private _refDataService: ReferenceDataService,
        private _sharedService: SharedService,
        public lookupService: LookupsService,
        private _blotterService: BlotterService
    ) { }

    ngOnInit(): void {
        this.minDate = new Date();

        this._formGroup = this._fb.group({
            contract: new FormControl(null, [Validators.required]),
            side: new FormControl('B', [Validators.required]),
            orderNo: new FormControl(null),
            tradeDate: new FormControl(new Date(), [Validators.required]),
            cpInd: new FormControl(null),
            traderSwap: new FormControl(null, [Validators.required]),
            qty: new FormControl(null, [Validators.required]),
            template: new FormControl(null, [Validators.required]),
            strike: new FormControl(null, [Validators.required]),
            price: new FormControl(null, [Validators.required]),
            strategyUsed: new FormControl(null, [Validators.required]),
            isMultiLeg: new FormControl(null, [Validators.required]),
            deletedReason: new FormControl(null),
            orderType: new FormControl('LMT', [Validators.required]),
            maturityDate: new FormControl(null, [Validators.required]),
            ratio: new FormControl(null),
            lifeCycleStatus: new FormControl('PENDING', [Validators.required]),
            openCloseIndicator: new FormControl("O", [Validators.required]),
            endClient: new FormControl(null, [Validators.required]),
            optionKind: new FormControl('STAN', [Validators.required]),
            settlementType: new FormControl('PM', [Validators.required]),
            exerciseStyle: new FormControl('A', [Validators.required])
        });

        this._formGroup.get('ratio')?.disable();

        this._formGroup.get('orderType')?.valueChanges
            .subscribe((orderType: any) => {
                this.showPrice = orderType === "MKT" ? false : true;
                if (this.showPrice) {
                    this._formGroup.get('price')?.setValidators([Validators.required]);
                    this._formGroup.get('price')?.enable();
                } else {
                    this._formGroup.get('price')?.setValidators(null);
                    this._formGroup.get('price')?.disable()
                }
            });

        this._formGroup.get('template')?.valueChanges
            .subscribe((template: any) => {
                this.selectedRule = template;
                if (template.isDelta) {
                    this.isDelta = true;
                } else {
                    this.isDelta = false;
                }

                if (this.isDelta) {
                    this._formGroup.get('ratio')?.setValidators([Validators.required])
                    this._formGroup.get('ratio')?.enable();
                } else {
                    this._formGroup.get('ratio')?.setValidators(null)
                    this._formGroup.get('ratio')?.disable();
                }
                this._formGroup.get('strategyUsed')?.setValue(template.text);
                this._formGroup.get('isMultiLeg')?.setValue(template.legs.length > 1);
            });

        this.getStrategyRules();
        this.getContracts();
        this.getTraderswap();
        this.getAccounts();
    }

    private getAccounts() {
        this._refDataService.getLegalEntities()
            .subscribe(response => {
                this.accounts = response;
            })
    }
    public setContract(contract: any) {
        console.log(contract);
        this._formGroup.get('contract')?.setValue(contract);
    }

    private getStrategyRules() {
        this._refDataService.getStrategyRule()
            .subscribe((response: StrategyRuleViewModel[]) => {
                this.rules = response;
            });
    }

    getContracts() {
        // this._refDataService.getContracts()
        //     .subscribe((response: any) => {
        //         this.contracts = response;
        //         this.selectedContract = this.contracts[0];
        //     });

        this._refDataService.getHotKeyContracts()
            .subscribe(response => {
                this.contractStrikes = response;

                if (response.length > 0) {
                    this._formGroup.get('contract')?.setValue(response[0].contract);
                }
            })
    }

    getTraderswap() {
        //console.log(this._sharedService.getUserDetails.defaultBroker);
        if (this._sharedService.getUserDetails.defaultBroker == "BACKOFFICE") {
            this._refDataService.getTraderSwaps()
                .subscribe((response: any) => {
                    this.traderSwaps = response;
                    this._formGroup.get('traderSwap')?.enable();
                });

        } else {
            this.traderSwaps = [];
            this.traderSwaps.push({ name: this._sharedService.getUserDetails.defaultBroker });
            this._formGroup.get('traderSwap')?.setValue(this._sharedService.getUserDetails.defaultBroker);
            this._formGroup.get('traderSwap')?.disable();
        }
    }

    public setTemplate() {

        // if (this.selectedContract.futureOrOption != this.selectedRule.futureOrOption) {
        //     this._dialogService.showErrorDialog("Validation Error", `The selected contract ${this.selectedContract.name} is ${this.selectedRule.futureOrOption ? "Future" : "Option"} 
        // 	and can be selected with ${this.selectedRule.futureOrOption ? "Future" : "Option"} contracts only`);
        //     return;
        // }


        var trade = this._formGroup.getRawValue() as TradeModel;

        this.hasValidationError = false;
        trade.username = this._sharedService.getUserDetails.username;
        this.onAdd.emit(trade);
        //trade.maturityDate = moment(trade.maturityDate).toDate();
        //trade.orderTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
        //trade.routeTime = trade.orderTime;
        // this._blotterService.validateEntryForm(trade)
        //     .subscribe({
        //         next: (response: any) => {
        //             console.log("Completed validation, raising event....onAdd")
        //             this.onAdd.emit(trade);
        //         },
        //         error: (err: any) => {
        //             this.hasValidationError = true;
        //             this.errorMessages = err.error;
        //             //this._dialogService.showErrorsDialog("Validation Error", err);
        //             console.log(err);
        //         }
        //     });

    }
}