<nav class="navbar bg-light navbar-expand-lg mb-4">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">
            <img src="/assets/DraxPrime-Logo-USEQ.png" style="max-height: 32px;">
        </a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="loginDisplay">
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="home">Working
                        Orders</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" aria-current="page"
                        routerLink="blotter/list">Completed Orders</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" aria-current="page"
                        routerLink="confirmations">Confirmations</a>
                </li>
            </ul>
            <div class="d-flex justify-content-end bg-warning text-dark">
                <button class="btn btn-outline-danger" type="button">
                    <b>{{currentTime}}</b>
                </button>


            </div>
            <div class="d-flex" *ngIf="loginDisplay">
                <button class="btn btn-outline-success" type="button">
                    <b>Username:</b> {{username}}
                </button>
                <button class="btn btn-danger" type="button" (click)="logout()">Logout</button>
            </div>
            <div class="d-flex" *ngIf="!loginDisplay">
                <button class="btn btn-outline-danger" type="button" (click)="login()">
                    Login
                </button>
            </div>
        </div>
    </div>
</nav>