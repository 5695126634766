import { Component, Output } from "@angular/core";
import { BlotterService } from "../services/blotter.service";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs";
import { TradeModel } from "../model/trade.model";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
    selector: 'audit-list',
    templateUrl: './audit-list.component.html',
})
export class AuditListComponent {

    @Output()
    public blotterId!: number;

    public trade!: TradeModel;
    public audits: any[] = [];
    public DATE_FORMAT_YMD_HMSS = "yyyy-MM-ddTHH:mm:ss";
    public DATE_FORMAT_YMD = "yyyy-MM-dd";
    constructor(private blotterService: BlotterService,
        public config: DynamicDialogConfig,
        private activatedRoute: ActivatedRoute) { }
    ngOnInit() {

        this.blotterId = this.config.data.blotterId;
        if (!this.blotterId) {
            this.activatedRoute.params.pipe(map((p) => p['testId'])).subscribe(d => this.blotterId = d.get('id'));

        }

        this.blotterService.getAuditDetails(this.blotterId).subscribe((response: any) => {
            this.audits = response;
        })

        this.blotterService.getBlotterById(this.blotterId)
            .subscribe((response: TradeModel) => {
                this.trade = response;
            })
    }
}