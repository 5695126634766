import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from '@progress/kendo-angular-notification';


@Injectable()
export class HttpStatusInterceptor implements HttpInterceptor {

	constructor(private notificationService: NotificationService) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap(evt => {
				if (evt instanceof HttpResponse) {
					if (evt.body && evt.body.success) {
						let message = '';
						switch (evt.status) {
							case 201:
								message = 'Blotter saved successfully';
								break;
							case 202:
								message = 'Blotter updated successfully';
								break;
							case 403:
								message = 'Authentication failure happened. You may have to login again.';
								break;
						}

						this.notificationService.show({
							content: message,
							hideAfter: 600,
							position: { horizontal: "center", vertical: "bottom" },
							animation: { type: "fade", duration: 400 },
							type: { style: "info", icon: true },
						});
					}
				}
			}));
	}
}
