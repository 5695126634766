import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { PrintModel } from '../model/print/print-blotter.model';
import { BlotterService } from '../services/blotter.service';
import { SharedService } from '../services/shared.service';
@Component({
	selector: 'print-blotter',
	templateUrl: './print-blotter.component.html',
	styleUrls: ['./print-blotter.component.css']
})
export class PrintBlotterComponent implements OnInit {

	@Input()
	public model: any;
	@Input()
	public ids: number[] = [];
	public printBlotters: PrintModel[] = [];
	message: string = "";
	showTable: boolean = false;
	constructor(public _sharedService: SharedService,
		public _blotterService: BlotterService) { }

	ngOnInit(): void {
		this.message = "Printed On:" + moment().format("YYYY-MM-DD hh:mm:ss") + " Printed By: " + this._sharedService.getUserDetails.username + ", Broker: " + this._sharedService.getUserDetails.defaultBroker
		var obs: any;
		if (this.ids.length > 0) {
			obs = this._blotterService.getPrintBlottersById(this.ids, this._sharedService.getUserDetails.defaultBroker);
		} else if (this.model == null) {
			obs = this._blotterService.getPrintBlotters(
				{
					"contract": null,
					"orderNo": null,
					"fromTradeDate": "1971-01-01",
					"traderSwap": null,
					"callPut": null,
					"side": null,
					"toTradeDate": "2023-12-31",
					"username": "dawsonb",
					"broker": "CHICAGOLAND"
				}
			)
		} else {
			obs = this._blotterService.getPrintBlotters(this.model);
		}

		obs.subscribe((response: any) => {
			this.printBlotters = response;
			this.showTable = true;
		});
	}

}
