<div class="container" *ngIf="requestInProgress">
    Loading, please wait......
</div>

<div *ngIf="!requestInProgress">
    <div class="row mt-3 ml-3 mb-2">
        <add-blotter (onAdd)="setTemplate($event)"></add-blotter>
    </div>
    <hr>
    <div [formGroup]="_formGroup" class="container-fluid">
    </div>
    <div class="border">
        <div>
            <div class="row" *ngIf="orders.length == 0">
                <div class="col-12">
                    <div class="mt-4 p-5 bg-light text-dark rounded">
                        <h1>No records found</h1>
                    </div>
                </div>
            </div>
            <div class="row" *ngFor="let order of orders">
                <div class="col-12">
                    <blotter-form [order]="order" (onOrderCancelled)="search()"></blotter-form>
                </div>
            </div>
        </div>
    </div>
</div>


<div kendoDialogContainer></div>
<div kendoWindowContainer></div>
<div id="print-div"></div>