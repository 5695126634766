<div class="row">
    <div class="col-12">
        <div class="mr-2">
            <button *ngFor="let button of searchButtonConfigs" type="button" class="btn btn-sm  btn-info"
                (click)="searchButtons(button)"><i class="pi pi-check" *ngIf="button.isSelected"></i>
                {{button.caption}}</button>
        </div>
    </div>

</div>
<div>
    <div class="d-flex justify-content-center">
        <hotkeys [firstItemSelected]="false" (onChange)="setContract($event)"></hotkeys>
    </div>
    <div [formGroup]="blotterListSearchForm">

        <div class="d-flex justify-content-center">
            <div class="hstack gap-2">

                <input type="text" pInputText class="form-control" placeholder="Order #" formControlName="orderNo"
                    style="max-width: 70px !important;">
                <p-calendar formControlName="fromTradeDate" dateFormat="yy-mm-dd" dataType="string" />
                <p-calendar formControlName="toTradeDate" dateFormat="yy-mm-dd" dataType="string" />
                <p-dropdown [options]="lookupService.callPuts" formControlName="callPut" optionLabel="label"
                    optionValue="label" [filter]="false" appendTo="body" [showClear]="true" placeholder="Select C/P" />
                <p-dropdown [options]="lookupService.sides" formControlName="side" optionLabel="label"
                    optionValue="label" [filter]="false" appendTo="body" [showClear]="true"
                    placeholder="Select sides" />
                <p-dropdown [options]="traderSwaps" formControlName="traderSwap" optionLabel="name" optionValue="name"
                    [filter]="true" filterBy="name" appendTo="body" placeholder="Select Desk" [showClear]="true" />
                <div class="btn-group">
                    <button type="button" class="btn btn-primary" (click)="loadBlotters()">Search</button>
                    <button type="button" class="btn btn-secondary" (click)="resetFilters()">Reset</button>
                </div>
            </div>
        </div>
    </div>
</div>


<p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRows" />

<div class="card mb-1" *ngFor="let order of orders">
    <div class="card-header">
        <div class="row">
            <div class="col-2">
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label class="col-form-label">Order #</label>
                    </div>
                    <div class="col-auto">
                        <strong>{{order.orderNo}}</strong>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label class="col-form-label">Client</label>
                    </div>
                    <div class="col-auto">
                        <strong>{{order.endClient}}</strong>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label class="col-form-label">Desk</label>
                    </div>
                    <div class="col-auto">
                        {{order.traderSwap}}
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label class="col-form-label">Original Client Order</label>
                    </div>
                    <div class="col-auto">
                        {{order.orderTime}}
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <label class="col-form-label">Contract</label>
                    </div>
                    <div class="col-auto">
                        {{order.contract}}
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row g-3 align-items-center">
                    <div class="col-auto">
                        <button class="btn btn-dark" (click)="copyOrder(order.orderNo)">
                            <i class="pi pi-ellipsis-v"></i> <i class="pi pi-copy"></i> Copy
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <table class="table  table-bordered table-striped">
            <thead>
                <tr>
                    <th>

                    </th>
                    <th>
                        Option Id
                    </th>
                    <th class="table-date-field">Maturity</th>
                    <th class="orderType">Order Type</th>
                    <th class="single-button">B/S</th>
                    <th class="single-button">C/P</th>
                    <th class="single-character-two-buttons">O/C</th>
                    <th class="table-number-field">Vol</th>
                    <th class="table-number-field">Strike</th>
                    <th class="table-number-field">Price</th>
                    <th class="table-number-field">Leaves Qty</th>
                    <th class="table-date-field-with-time">Original Broker Instruction</th>
                    <th class="table-date-field-with-time">Mod. Req On</th>
                    <th class="table-date-field-with-time">Mod. Ack On</th>
                    <th>Status</th>
                    <th>Route Status</th>
                    <th>Sys Modified On
                    </th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let trade of order.blotters">
                    <td>
                        <button class="btn btn-link" (click)="show(trade.id)">Audit</button>
                    </td>
                    <td>
                        {{trade.optionId}}
                    </td>
                    <td class="table-date-field">
                        {{trade.maturityDate | date: DATE_FORMAT_YMD}}
                    </td>
                    <td class="orderType">
                        {{trade.orderType}}
                    </td>
                    <td class="single-button">
                        {{trade.side}}
                    </td>
                    <td class="single-button">
                        {{trade.callPut}} </td>
                    <td class="single-character-two-buttons">
                        {{trade.openCloseIndicator}}
                    </td>
                    <td class="table-number-field">
                        {{trade.qty}}
                    </td>
                    <td class="table-number-field">
                        {{trade.strike}}
                    </td>
                    <td class="table-number-field">
                        {{trade.price}}
                    </td>
                    <td class="table-number-field">
                        {{trade.leavesQty}}
                    </td>
                    <td class="table-date-field-with-time">
                        {{trade.routeTime | date : DATE_FORMAT_YMD_HMSS}}
                    </td>
                    <td class="table-date-field-with-time">
                        {{trade.modifyRequestedOn | date : DATE_FORMAT_YMD_HMSS}}
                    </td>
                    <td class="table-date-field-with-time">
                        {{trade.modifyAckOn | date : DATE_FORMAT_YMD_HMSS}}
                    </td>
                    <td>{{trade.orderStatus}}</td>
                    <td>{{trade.routeEvent}}</td>
                    <td>
                        {{trade.modifiedOn | date : DATE_FORMAT_YMD_HMSS}}
                    </td>

                </tr>


            </tbody>
        </table>
    </div>
</div>



<div kendoDialogContainer></div>