import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ReferenceDataService } from "../../../services/reference-data.service";

@Component({
    selector: 'hotkeys',
    templateUrl: './hotkeys.component.html'
})
export class HotKeysComponent implements OnInit {
    public contracts: any[] = [];
    public _selectedContract!: string | null;

    @Output() onChange = new EventEmitter<string | null>();
    @Input() firstItemSelected: boolean = true;

    constructor(private _refDataService: ReferenceDataService) { }

    ngOnInit() {
        this._refDataService.getHotKeyContracts()
            .subscribe(response => {
                this.contracts = response;
                if (this.firstItemSelected) {
                    if (response.length > 0) {
                        this._selectedContract = response[0].name;
                        //this.onChange.emit(this._selectedContract);
                    }

                }
            })
    }

    setContract(contract: string) {
        this._selectedContract = contract;
        this.onChange.emit(this._selectedContract);
    }

    clearContract() {
        this._selectedContract = null;
        this.onChange.emit(null);
    }
}