<!-- <form novalidate [formGroup]="emailPreviewForm" class="container border shadow">
    <div>
        <div *ngIf="isDataLoaded">
            <br />
            <div class="modal-body">
                <div class="text-bg-primary p-3">Email Preview</div>
                <hr />
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <label class="col-2 col-form-label">To</label>
                            <div class="col-9">
                                <div class="form-group">
                                    <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="to">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <label class="col-2 col-form-label">CC</label>
                            <div class="col-9">
                                <div class="form-group">
                                    <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="cc">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <label class="col-2 col-form-label">Subject</label>
                            <div class="col-9">
                                <input type="text" class="form-control" value="Test Subject"
                                    formControlName="subject" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <legend>Email Edit</legend>
                    <fieldset>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Body Header</label>
                                    <kendo-editor formControlName="bodyHeader" style="height: 100px;"></kendo-editor>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <br />
            <div class="modal-body">
                <hr />
                <fieldset>
                    <div class="horizontal-scrollable">
                        <div id="previewEmailBody" #previewEmailBody>
                            <div [innerHTML]="emailPreviewForm.value.bodyHeader  | safe: 'html'"></div>
                            <div [innerHTML]="emailPreviewForm.value.body  | safe: 'html'"></div>
                            <div [innerHTML]="emailPreviewForm.value.bodyFooter  | safe: 'html'"></div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="modal-footer" *ngIf="isDataLoaded" style="justify-content: flex-start !important;">
            <br />
            <button [attr.disabled]="emailPreviewForm.valid? null : ''"
                [attr.title]="!emailPreviewForm.valid? 'Form is invalid.' : ''" class="btn btn-lg btn-primary"
                type="button" (click)="sendEmail()">
                <i class="k-icon k-i-envelop"></i> Send
            </button>
        </div>
    </div>
</form> -->

<iframe width="100%" height="100%" [src]="safeUrl()"></iframe>