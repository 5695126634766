import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '../account.service';


@Injectable()
export class AuthGuardService implements CanActivate {
	constructor(public auth: AccountService, public router: Router) { }
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (!this.auth.isUserLoggedIn()) {
			this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
			return false;
		}
		return true;
	}
}