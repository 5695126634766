import { Component, EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TokenResponseModel } from "../model/user-detail.model";
import { AccountService } from "../services/account.service";
import { SharedService } from "../services/shared.service";



@Component({
	templateUrl: './login.component.html',
	selector: 'login',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	userDetails!: TokenResponseModel;
	public form!: FormGroup;
	serverError!: string;
	returnUrl?: string;

	constructor(private accountService: AccountService,
		private _fb: FormBuilder, private route: Router, private activatedRoute: ActivatedRoute, private _sharedService: SharedService) { }

	ngOnInit(): void {
		this.form = this._fb.group({
			userName: ['', Validators.required],
			password: ['', Validators.required]
		});

		this.activatedRoute.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl']
		})
	}

	login() {
		// this.accountService.loginUser(this.form.value.userName, this.form.value.password);
		// this.accountService.userOnlineStatusChange.subscribe(response => {
		// 	if (response) {
		// 		if (this.returnUrl) {
		// 			this.route.navigateByUrl(this.returnUrl);
		// 		}
		// 		else {
		// 			this.route.navigateByUrl('/home');
		// 		}
		// 	}
		// });

	}
}