import { Component, ElementRef, EventEmitter, Host, Injector, Input, OnInit, Optional, Output, Self, ViewChild, forwardRef } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, ValidationErrors, Validator, Validators } from "@angular/forms";
import * as moment from "moment";
import { OverlayPanel } from "primeng/overlaypanel";
@Component({
    selector: 'date-time',
    templateUrl: './date-time.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateTimeComponent),
        multi: true
    },
    { provide: NG_VALIDATORS, useExisting: DateTimeComponent, multi: true }]
})
export class DateTimeComponent implements OnInit, ControlValueAccessor {


    @Input()
    disabled!: boolean;
    @Input()
    orderTime!: string;

    private _oldTime!: Date;

    @Output() onBlur = new EventEmitter<any>();
    public dateIsInValid: boolean = false;
    public hasValueChanged: boolean = false;
    public showOverlay: boolean = true;
    public _errorMessages: string[] = [];
    private DATE_FORMAT_TO_MS = "YYYY-MM-DDTHH:mm:ss.SSS";

    value: any = '';
    originalValue: any = '';
    @ViewChild('inputElement') inputElement!: ElementRef;
    @ViewChild('op', { static: true }) op!: OverlayPanel;

    public onChange() {
        //this.validate();
    }

    public change(value: any) {

        this.value = value;
        this.onChange();
        this.onTouched();
        //this.validate();
    }
    public onTouched() { }

    ngOnInit() {
        this._oldTime = this.value;
    }

    formatDate(date: Date): string {
        return moment(date).format(this.DATE_FORMAT_TO_MS);
    }

    validate() {
        this._errorMessages = [];
        //debugger;
        if (!moment(this.value, this.DATE_FORMAT_TO_MS).isValid()) {
            this._errorMessages.push(`Invalid date and time`);
            return;
        }

        if (!moment(this.value, this.DATE_FORMAT_TO_MS).isSame(moment(), 'day')) {
            this._errorMessages.push(`Only today's date is permitted.`);
            //this.toggle(this.inputElement, false);
            return;
        }

        if (this._errorMessages.length == 0)
            return null;
        else {
            return {
                error: this._errorMessages
            }
        }

    }


    writeValue(obj: any): void {
        this.value = obj;
        if (this.value == "")
            this.value = null;
        this.originalValue = this.value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    toggle($event: any, hide: boolean) {
        if (this.orderTime == null || this.orderTime == "")
            return;

        if (hide) {
            this.op.hide();
            return;
        }

        this.op.toggle(null, this.inputElement.nativeElement);


    }
}