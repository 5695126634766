import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PrintModel } from "../model/print/print-blotter.model";
import { TradeModel, TradeModelGroup } from "../model/trade.model";
import { ConfigurationService } from 'projects/shared/src/lib/services/config.service';
import { SharedService } from "./shared.service";

@Injectable()
export class OrderEventService {

	private _url: string = '';
	private _httpClient: HttpClient;
	public constructor(httpClient: HttpClient,
		config: ConfigurationService,
		private _sharedService: SharedService) {
		this._httpClient = httpClient;
		this._url = config.get('apiUrl');
	}



	public cancel(blotterId: number, cancelRequestOn: string, clientCancellationAckOn: string,
		cancelledOn: string, routeTime: string, leavesQty?: number): Observable<any> {
		return this._httpClient.post<any>(`${this._url}/api/order/event/cancel`, {
			id: blotterId,
			cancelRequestOn: cancelRequestOn,
			clientCancellationAckOn: clientCancellationAckOn,
			cancelledOn: cancelledOn,
			routetime: routeTime,
			username: this._sharedService.getUserDetails.username,
			leavesQty: leavesQty
		});
	}

	public modify(blotterId: number, modifyRequestedOn: string, clientModificationAckOn: string,
		modifyAckOn: string, routeTime: string, leavesQty?: number): Observable<any> {
		return this._httpClient.post<any>(`${this._url}/api/order/event/modify`, {
			id: blotterId,
			modifyRequestedOn: modifyRequestedOn,
			clientModificationAckOn: clientModificationAckOn,
			modifyAckOn: modifyAckOn,
			routetime: routeTime,
			username: this._sharedService.getUserDetails.username,
			leavesQty: leavesQty
		});
	}


}