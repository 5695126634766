import { Component, ElementRef, EventEmitter, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import * as moment from 'moment';
import { UserDetails } from '../model/user-detail.model';
import { BlotterService } from '../services/blotter.service';
import { ReferenceDataService } from '../services/reference-data.service';
import { SharedService } from '../services/shared.service';
import { TradeModel, TradeModelGroup } from '../model/trade.model';
import { StrategyRuleViewModel } from '../model/strategy-rule.model';
import { PriceSummaryComponent } from '../price-summary/price-summary.component';
import { PrintService } from '../services/print.service';
import { BrokerViewModel } from '../model/broker.model';
import { DateTimeConstantService } from '../constants/date-time.constant.service';
import { LookupsService } from '../shared/lookups.service.component';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-blotter-list',
    templateUrl: './blotter-list.component.html',
    styles: [`
	.table-dark {
    	background-color: #e0e2e4 !important;
}
	`]
})
export class BlotterListComponent implements OnInit {

    private _fb: FormBuilder;
    public _formGroup!: FormGroup;
    private _refDataService: ReferenceDataService;
    public contracts!: any[];
    public traderSwaps!: any[];
    public selectedContract: any;
    public _userDetail!: UserDetails;
    public disableCP: boolean = false;
    public rules: StrategyRuleViewModel[] = [];
    public _selectedRule: any;
    public tradeListModel: TradeModel[] = [];
    public orders: TradeModelGroup[] = [];
    public selectedBrokerSymbol: string = "";
    public brokers: BrokerViewModel[] = [];
    public bracketTimes: any[] = [];
    public selectedBracket: any;
    public _enableDeleteButton: boolean = false;
    public _cancelDelete: boolean = false;
    public minDate!: Date;
    public minDateWithTime!: Date;
    public maxDateWithTime!: Date;
    public DB_FORMAT_TIME = "YYYY-MM-DD";
    public requestInProgress: boolean = true;
    private initialLoad: boolean = true;
    public searchValue: string = "";


    public newTradesAdded: EventEmitter<any> = new EventEmitter<any>();
    filteredBrokers!: any[];
    showCopyOrder: boolean = false;
    copyOrderNo: string = "";
    isDelta: boolean = false;

    @ViewChild('copyOrderNoInput') copyOrderNoInput!: ElementRef;
    @ViewChild('deletedReasonInput') deletedReasonInput!: ElementRef;


    @ViewChild(PriceSummaryComponent)
    private priceSummaryComponent!: PriceSummaryComponent;


    constructor(
        private _blotterService: BlotterService,
        refDataService: ReferenceDataService,
        fb: FormBuilder,
        private _notificationService: NotificationService,
        private _sharedService: SharedService,
        private _container: ViewContainerRef,
        private _printService: PrintService,
        private _dateTimeConstantService: DateTimeConstantService,
        public lookupService: LookupsService) {
        this._fb = fb;
        this._refDataService = refDataService;
    }

    ngOnInit(): void {
        this._sharedService.removeEmailPreviewOrders();
        this.minDate = new Date();
        this.minDateWithTime = moment().subtract(10, 'minute').toDate();
        this.minDateWithTime = moment().add(2, 'minute').toDate();

        this._formGroup = this._fb.group({
            filter: this._fb.group({
                contract: new FormControl(null),
                orderNo: new FormControl(null),
                fromTradeDate: new FormControl(null),
                traderSwap: new FormControl(null),
                callPut: new FormControl(null),
                side: new FormControl(null),
                toTradeDate: new FormControl(null)
            })
        });

        this._formGroup.get('filter.fromTradeDate')?.setValue(moment().subtract(15, 'days').format(this.DB_FORMAT_TIME));
        this._formGroup.get('filter.toTradeDate')?.setValue(moment(new Date()).format(this.DB_FORMAT_TIME));

        this._userDetail = this._sharedService.getUserDetails;

        forkJoin([this._refDataService.getStrategyRule(), this._refDataService.getHotKeyContracts(),
        this._refDataService.getTraderSwaps()])
            .subscribe({
                next: ([strategyResponse, contractsResponse, traderSwapResponse]) => {
                    this.requestInProgress = true;
                    this.rules = strategyResponse;
                    this.traderSwaps = traderSwapResponse;
                    this.contracts = contractsResponse;
                    //this.getStrategyRules();
                    this.getTraderswap();
                    //this.getContracts();
                    this.getDraftBlotters();
                },
                error: (error: any) => {
                    this.requestInProgress = false;
                },
                complete: () => {
                    this.requestInProgress = false;
                },
            })

        this.newTradesAdded.subscribe(() => {
            this.getDraftBlotters();
        });

        if (this.initialLoad) {
            this.search();
            this.registerFilterEvents();
        }
    }

    private registerFilterEvents() {
        // this._formGroup.get('filter')?.valueChanges.subscribe(() => {
        // 	if (this.initialLoad == false) {
        // 		console.log("from value change event filter");
        // 		this.search();
        // 	} else {
        // 		this.initialLoad = false;
        // 	}
        // });
    }


    public setDate(event: any, trade: any, fieldName: string) {
        if (!event.isValid) {
            console.log("invalid date for fields", fieldName, event.value)
        } else {
            trade[fieldName] = event.value;
        }

        console.log(trade);
    }

    public search() {
        //this.save(false, true, false);
        const rawValue = this._formGroup.get('filter')?.getRawValue();
        //debugger;
        const fromDate = rawValue.fromTradeDate;
        const toDate = rawValue.toTradeDate;
        rawValue.fromTradeDate = fromDate == null ? "1971-01-01" : moment(fromDate).format(this.DB_FORMAT_TIME);
        rawValue.toTradeDate = toDate == null ? moment().format(this.DB_FORMAT_TIME) : rawValue.toTradeDate;
        rawValue.username = this._sharedService.getUserDetails.username;

        rawValue.traderSwap = this._sharedService.getUserDetails.defaultBroker;
        rawValue.isBackOffice = this._sharedService.getUserDetails.defaultBroker == "BACKOFFICE";

        this._blotterService.getDraftOrders(rawValue)
            .subscribe((response: TradeModelGroup[]) => {
                this.orders = response;
            })

    }

    public getBrokers() {
        this._refDataService.getBrokers('')
            .subscribe((response: BrokerViewModel[]) => {
                this.brokers = response;

                this.brokers.forEach((item: BrokerViewModel) => {
                    item.displayName = item.brokerSymbol + "(" + item.fullName + ")";
                })
            })
    }

    filterBrokers(event: any) {

        let filtered: any[] = [];
        let query = event.query;

        if (query == "" || query == null || query == undefined) {
            this.filteredBrokers = this.brokers;
            return;
        }
        for (let i = 0; i < (this.brokers as any[]).length; i++) {
            let country = (this.brokers as any[])[i];
            if (country.brokerSymbol.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(country);
            }
        }

        //console.log(filtered);
        this.filteredBrokers = filtered;
    }



    public setTemplate($event: TradeModel) {

        $event.username = this._sharedService.getUserDetails.username;
        this._blotterService.addNewBlotter($event, $event.strategyUsed)
            .subscribe({
                next: response => {
                    this.getDraftBlotters();
                },
                error: err => {
                    console.log(err.error);
                }
            });

        this.newTradesAdded.emit(true);
    }

    getTraderswap() {
        if (this._sharedService.getUserDetails.defaultBroker == "BACKOFFICE") {
            this._formGroup.get('main.traderSwap')?.enable();
        } else {
            this.traderSwaps = [];
            this.traderSwaps.push({ name: this._sharedService.getUserDetails.defaultBroker });
            this._formGroup.get('filter.traderSwap')?.setValue(this._sharedService.getUserDetails.defaultBroker);
            this._formGroup.get('filter.traderSwap')?.disable();
        }
    }

    getContracts() {
        this._refDataService.getHotKeyContracts()
            .subscribe((response: any) => {
                this.contracts = response;
                this.selectedContract = this.contracts[0];
                //this._formGroup.get('main.contract')?.setValue(this.selectedContract.name);
            });

    }

    get getUserDetails(): UserDetails {
        const userDetail: any = localStorage.getItem('userDetails');
        return JSON.parse(userDetail);
    }

    getDraftBlotters() {

        this.search();

    }


    public getUtcDateTime(dateTime: string | null): string {
        return this._dateTimeConstantService.getUtcTime(dateTime != null ? dateTime : moment().format(this._dateTimeConstantService.DbDateTimeFormat));
    }


    public reset() {
        if (this._formGroup.get('filter.contract')?.value !== null) {
            this._formGroup.get('filter.contract')?.setValue(null);
        }

        if (this._formGroup.get('filter.orderNo') !== null) {
            this._formGroup.get('filter.orderNo')?.setValue(null);
        }

        if (this._formGroup.get('filter.fromTradeDate')?.value !== null) {
            this._formGroup.get('filter.fromTradeDate')?.setValue(null);
        }

        if (this._formGroup.get('filter.callPut')?.value !== null) {
            this._formGroup.get('filter.callPut')?.setValue(null);
        }

        if (this._formGroup.get('filter.side')?.value !== null) {
            this._formGroup.get('filter.side')?.setValue(null);
        }

    }

}
