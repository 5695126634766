export class FOFilterModel {
    client?: string;
    orderNo?: string;
    side?: string;
    contract?: string;
    callPut?: string;
    traderSwap?: string;
    fromTradeDate?: string;
    toTradeDate?: string;
    pageNo: number = 0;
    pageSize!: number;
    userTraderSwap!: string;
    totalRows?: number;

    constructor() { }
}