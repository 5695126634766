import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormArray, FormGroup, Validators } from "@angular/forms";
import { DateTimeConstantService } from "../../constants/date-time.constant.service";

import * as moment from "moment";
import { OrderEventService } from "../../services/order-event.service";

@Component({
    selector: 'cancel-order',
    templateUrl: './cancel-order.component.html',
    providers: [
        OrderEventService
    ]
})
export class CancelOrderComponent {

    @Input()
    formGroup!: FormGroup;
    @Input()
    orderDetailForm!: FormGroup;
    @Input()
    tradesForm!: FormArray;
    @Output() onExecute = new EventEmitter<string>();
    @Output() OnCancel = new EventEmitter<any>();
    public _errorMessages: string[] = [];

    constructor(public dateTimeService: DateTimeConstantService,
        private cancelOrderService: OrderEventService
    ) { }


    ngOnInit() {
        //this.orderDetailForm.get('leavesQty')?.setValue(0);
        this.orderDetailForm.get('leavesQty')?.disable();
    }

    public cancelOrder(orderNo: string) {

        this.tradesForm.controls.filter(d => d.get('orderNo')?.value == orderNo)
            .forEach((trade: AbstractControl<any, any>) => {
                trade.get('cancelRequestOn')?.setValue(this.orderDetailForm.get('cancelRequestOn')?.value);
                trade.get('cancelledOn')?.setValue(this.orderDetailForm.get('cancelledOn')?.value);
                // trade.get('leavesQty')?.setValue(0);
                // trade.get('leavesQty')?.disable();
                trade.get('clientCancellationAckOn')?.setValue(this.orderDetailForm.get('clientCancellationAckOn')?.value);
                //trade.get('isCancelled')?.setValue(true);
            });

        this.cancelOrderService.cancel(this.tradesForm.controls[0].get('id')?.value,
            this.tradesForm.controls[0].get('cancelRequestOn')?.value,
            this.tradesForm.controls[0].get('clientCancellationAckOn')?.value,
            this.tradesForm.controls[0].get('cancelledOn')?.value,
            this.tradesForm.controls[0].get('routeTime')?.value,
            this.tradesForm.controls[0].get('leavesQty')?.value)
            .subscribe({
                next: (response: any) => {
                    this.onExecute.emit(orderNo);
                },
                error: (err) => {
                    this._errorMessages = [];
                    if (err.status == 417) {
                        var message = err.error;
                        if (Array.isArray(err.error)) {
                            err.error.forEach((response: any) => {
                                this._errorMessages.push(response);
                            })
                            // dialog.messages = err.error;
                        } else {
                            this._errorMessages.push(err.error);
                        }

                        console.log(this._errorMessages);
                    } else {
                        this._errorMessages.push(message);
                    }
                },
                complete: () => { }
            })


    }

    public setAllToCurrentTime() {
        const currentTime = moment().format(this.dateTimeService.DbDateTimeFormat);
        this.orderDetailForm.get('cancelRequestOn')?.setValue(currentTime);
        this.orderDetailForm.get('cancelledOn')?.setValue(currentTime);
        this.orderDetailForm.get('clientCancellationAckOn')?.setValue(currentTime);
    }

    public exit() {
        this.orderDetailForm.get('cancelRequestOn')?.setValidators(null)
        this.orderDetailForm.get('cancelRequestOn')?.setValue(null);
        this.orderDetailForm.get('cancelledOn')?.setValidators(null);
        this.orderDetailForm.get('cancelledOn')?.setValue(null);
        this.orderDetailForm.get('leavesQty')?.setValidators(null)
        this.orderDetailForm.get('clientCancellationAckOn')?.setValidators(null);
        this.orderDetailForm.get('clientCancellationAckOn')?.setValue(null);
        this.orderDetailForm.updateValueAndValidity();
        this.OnCancel.emit();
    }
}