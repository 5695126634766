import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmailPreviewRequest } from "../model/email-preview-request.model";
import { ConfigurationService } from 'projects/shared/src/lib/services/config.service';

@Injectable()
export class EmailService {

    private _url: string = '';
    private _httpClient: HttpClient;
    public constructor(httpClient: HttpClient, config: ConfigurationService) {
        this._httpClient = httpClient;
        this._url = config.get('recapUrl');
    }

    public getBlottersForPreview(orders: EmailPreviewRequest[]): Observable<any> {
        return this._httpClient.post(`${this._url}/api/report/preview/blotters/1/1`, orders);
    }

    public sendEmail(email: any, userName?: string): Observable<any> {
        return this._httpClient.post(`${this._url}/api/email/send/${userName}`, email);
    }


}