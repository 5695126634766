import { Injectable } from "@angular/core";
import * as moment from 'moment';
@Injectable({
    providedIn: 'root'
})
export class DateTimeConstantService {
    private DB_FORMAT_TIME: string = "YYYY-MM-DDTHH:mm:ss";

    public get DbDateTimeFormat() {
        return this.DB_FORMAT_TIME;
    }

    public getUtcTime(date: string): string {
        const time = moment(date);
        return moment.utc(time).format(this.DB_FORMAT_TIME);
    }

    // public get DbFormatWithTime() {
    //     return moment().format(this.DB_FORMAT_TIME);
    // }

    public DbFormatWithTimeStartOfMonth(dt: Date): string {
        return moment(dt).format(this.DB_FORMAT_TIME);
    }

    public get DateTimeMask() {
        return "9999-99-99T99:99:99";
    }

    public get DateTimeMaskPlaceholder() {
        return "yyyy-MM-ddTHH:mm:ss";
    }

}