<style>
    table {
        font-size: 10px !important;
    }

    tr>td {
        font-size: 10px !important;
    }

    tr>th {
        font-size: 10px !important;
    }
</style>

<h2 *ngIf="audits.length == 0">
    No records found
</h2>
<h2 *ngIf="audits.length > 0">
    Order No: {{trade.orderNo}}
</h2>
<table class="table table-borderd" *ngIf="audits.length > 0">
    <thead>
        <tr>
            <th>Contract</th>
            <th>Maturity</th>
            <th>B/S</th>
            <th>C/P</th>
            <th>O/C</th>
            <th>Qty</th>
            <th>LQty</th>
            <th>Strike</th>
            <th>Price</th>
            <th>Ord. Type</th>
            <th>Route On</th>
            <th>Order Status</th>
            <th>Route Event</th>
            <th>Option Id</th>
            <th>
                Client Requested Time
            </th>
            <th>
                Broker Client Requested Ack Time
            </th>
            <th>
                Broker Sent Time
            </th>
            <th>
                Cancel Request On
            </th>
            <th>
                Client Cancel Ack On
            </th>
            <th>
                Cancelled On
            </th>
            <th>Audit Created On</th>
            <!-- <th>Option Kind</th>
            <th>Sett. Type</th>
            <th>Exercise. Style</th> -->
            <th>Row Version</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let audit of audits">
            <td>
                {{audit.contract}}
            </td>
            <td>
                {{audit.maturityDate | date: DATE_FORMAT_YMD}}
            </td>
            <td>
                {{audit.side}}
            </td>
            <td>
                {{audit.callPut}}
            </td>
            <td>
                {{audit.openCloseIndicator}}
            </td>
            <td>
                {{audit.qty}}
            </td>
            <td>
                {{audit.leavesQty}}
            </td>
            <td>
                {{audit.strike}}
            </td>
            <td>
                {{audit.price}}
            </td>
            <td>
                {{audit.orderType}}
            </td>
            <td>
                {{audit.routeTime | date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{audit.orderStatus}}
            </td>
            <td>
                {{audit.routeEvent}}
            </td>
            <td>
                {{audit.optionId}}
            </td>
            <td>
                {{audit.modifyRequestedOn | date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{audit.clientModificationAckOn| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{audit.modifyAckOn| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{audit.cancelRequestOn| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{audit.clientCancellationAckOn| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{audit.cancelledOn| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{audit.catAuditCreatedOn}}
            </td>
            <!-- <td>
                {{audit.optionKind}}
            </td>
            <td>
                {{audit.settlementType}}
            </td>
            <td>
                {{audit.exerciseStyle}}
            </td> -->
            <td>
                {{audit.rowVersion}}
            </td>
        </tr>
        <tr>
            <td>
                {{trade.contract}}
            </td>
            <td>
                {{trade.maturityDate | date: DATE_FORMAT_YMD}}
            </td>
            <td>
                {{trade.side}}
            </td>
            <td>
                {{trade.callPut}}
            </td>
            <td>
                {{trade.openCloseIndicator}}
            </td>
            <td>
                {{trade.qty}}
            </td>
            <td>
                {{trade.leavesQty}}
            </td>
            <td>
                {{trade.strike}}
            </td>
            <td>
                {{trade.price}}
            </td>
            <td>
                {{trade.orderType}}
            </td>
            <td>
                {{trade.routeTime| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{trade.orderStatus}}
            </td>
            <td>
                {{trade.routeEvent}}
            </td>
            <td>
                {{trade.optionId}}
            </td>
            <td>
                {{trade.modifyRequestedOn | date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{trade.clientModificationAckOn| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{trade.modifyAckOn| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{trade.cancelRequestOn| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{trade.clientCancellationAckOn| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{trade.cancelledOn| date: DATE_FORMAT_YMD_HMSS}}
            </td>
            <td>
                {{trade.catAuditCreatedOn}}
            </td>
            <!-- <td>
                    {{audit.optionKind}}
                </td>
                <td>
                    {{audit.settlementType}}
                </td>
                <td>
                    {{audit.exerciseStyle}}
                </td> -->
            <td>
                Latest
            </td>
        </tr>
    </tbody>
</table>