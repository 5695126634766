<div class="container-fluid">
    <div class="row" *ngIf="messages.length > 0">
        <table class="table table-light">
            <tr *ngFor="let message of messages">
                <td>{{message}}</td>
            </tr>
        </table>
    </div>
    <div class="row">
        {{message}}
    </div>
</div>