import { Component, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-email-preview',
	templateUrl: './email-preview.component.html',
	styleUrls: ['./email-preview.component.css']
})
export class EmailPreviewComponent {

	@Output()
	public url!: string;

	//public safeUrl!: SafeUrl;

	constructor(private sanitizer: DomSanitizer) { }

	ngOnInit() {
		//this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.url);
		console.log(this.url);
	}

	safeUrl() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
	}
	// public emailPreviewForm!: FormGroup;
	// public isDataLoaded = false;
	// public IsPreviewEmailTemplate = true;
	// public IsPreviewEmail = true;
	// public to = '';
	// public cc = '';
	// private selectedIds: any[] = [];
	// @Input() orders: EmailPreviewRequest[] = [];

	// isReady: boolean = false;
	// emailSent: boolean = false;
	// emailPreview: any;
	// public blotters: any[] = [];

	// public pasteCleanupSettings = {
	// 	convertMsLists: true,
	// 	removeHtmlComments: true,
	// 	// stripTags: ['span', 'h1'],
	// 	// removeAttributes: ['lang'],
	// 	removeMsClasses: true,
	// 	removeMsStyles: true,
	// 	removeInvalidHTML: true,
	// };

	// @ViewChild('previewEmailBody') previewEmailBody!: ElementRef;

	// constructor(private _emailService: EmailService, private _sharedService: SharedService,
	// 	private _fb: FormBuilder, private _router: Router) { }

	// ngOnInit(): void {

	// 	var orders = this._sharedService.getEmailPreviewOrders();
	// 	if (orders != null)
	// 		this.orders = orders;

	// 	this.emailPreviewForm = this._fb.group({
	// 		to: [''],
	// 		cc: [''],
	// 		subject: ['', Validators.required],
	// 		bodyFooter: [''],
	// 		body: [''],
	// 		bodyHeader: ['']
	// 	});

	// 	this._emailService.getBlottersForPreview(this.orders)
	// 		.subscribe((response: any) => {
	// 			this.isDataLoaded = true;
	// 			this.mapFormData(response);
	// 		});

	// 	// this._sharedService.currentMessage.subscribe(orders => {
	// 	// 	this.orders = orders;


	// 	// });

	// }

	// public addTag(event: any, array: any[], control: FormArray) {
	// 	if (event === '') {
	// 		array.pop();
	// 		return;
	// 	}
	// 	control.push(this.getNewTag(event));
	// }

	// public removeTag(event: any, control: FormArray) {
	// 	for (let i = 0; i < control.value.length; i++) {
	// 		if (event === control.value[i].tag) {
	// 			control.removeAt(i);
	// 			return;
	// 		}
	// 	}
	// }

	// private getNewTag(value: any): FormGroup {
	// 	return this._fb.group({ tag: value });
	// }

	// public sendEmail() {
	// 	const body = this.previewEmailBody.nativeElement.innerHTML;
	// 	this.emailPreviewForm.controls['body'].setValue(body);
	// 	const rawValue = this.emailPreviewForm.getRawValue();
	// 	rawValue.ids = this.selectedIds;
	// 	rawValue.to = this.to;
	// 	rawValue.cc = this.cc;
	// 	this._emailService.sendEmail(rawValue, this._sharedService.getUserDetails.username)
	// 		.subscribe(response => {
	// 			this._router.navigate(['home']);
	// 			//this._windowRef.close();
	// 		});
	// }

	// private mapFormData(emailTemplatePreview: any) {
	// 	this.emailPreviewForm.controls['subject'].setValue(emailTemplatePreview.subject);
	// 	this.emailPreviewForm.controls['body'].setValue(emailTemplatePreview.body);
	// 	this.selectedIds = emailTemplatePreview.ids;
	// 	this.to = emailTemplatePreview.to + `; ${this._sharedService.getUserDetails.emailAddress}`;
	// 	this.cc = emailTemplatePreview.cc;

	// }

	// public close() {

	// }
}
