<div class="animate__animated animate__fadeIn" tabindex="0" [formGroup]="_formGroup">
    <div class="d-flex justify-content-center mb-4">
        <hotkeys [firstItemSelected]="false" (onChange)="setContract($event)"></hotkeys>
    </div>
    <div class="d-flex justify-content-center">
        <div class="hstack gap-2">
            <div class="col-auto">
                <p><label for="endClient">Client</label></p>
                <p-dropdown [options]="accounts" formControlName="endClient" optionLabel="accountName"
                    optionValue="accountName" [filter]="true" filterBy="accountName" appendTo="body"
                    placeholder="Select Client" [showClear]="true" [style]="{'width':'300px'}" id="endClient" />

            </div>
            <div class="col-auto">
                <p>&nbsp;</p>
                <p-selectButton [options]="lookupService.sides" formControlName="side" optionLabel="label"
                    optionValue="value" id="sides"></p-selectButton>
            </div>
            <div class="col-auto">
                <p>&nbsp;</p>
                <p-selectButton [options]="lookupService.openClose" formControlName="openCloseIndicator"
                    optionLabel="label" optionValue="value"></p-selectButton>
            </div>
            <div class="col-auto">
                <p>&nbsp;</p>
                <p-selectButton [options]="lookupService.optionKind" formControlName="optionKind" optionLabel="label"
                    optionValue="value"></p-selectButton>
            </div>
            <div class="col-auto">
                <p>&nbsp;</p>
                <p-selectButton [options]="lookupService.settlementType" formControlName="settlementType"
                    optionLabel="label" optionValue="value"></p-selectButton>
            </div>

            <div class="col-auto">
                <p>&nbsp;</p>
                <p-selectButton [options]="lookupService.exerciseStyle" formControlName="exerciseStyle"
                    optionLabel="label" optionValue="value"></p-selectButton>
            </div>

            <div class="col-auto">
                <p><label for="traderSwap">Trader</label></p>
                <p-dropdown [options]="traderSwaps" formControlName="traderSwap" optionLabel="name" optionValue="name"
                    [filter]="true" filterBy="name" appendTo="body" placeholder="Select Desk" [showClear]="true"
                    [style]="{'width':'160px'}" id="traderSwap" />
            </div>
            <div class="col-auto">
                <p><label for="template">Strategy</label></p>
                <p-dropdown [options]="rules" formControlName="template" id="template" optionLabel="text"
                    [filter]="true" filterBy="name" appendTo="body" placeholder="Select Strategy" [showClear]=" true"
                    [style]="{'width':'220px'}">
                    <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2">
                            <span>{{ selectedRule.text }}</span>&nbsp;
                            <span class="text-bg-success" *ngIf="selectedRule.legs.length > 1">ML</span>
                            <span class="text-bg-info" *ngIf="selectedRule.legs.length == 1">SL</span>
                        </div>
                    </ng-template>
                    <ng-template let-rule pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <span>{{ rule.text }}</span>&nbsp;
                            <span class="text-bg-success" *ngIf="rule.legs.length > 1">ML</span>
                            <span class="text-bg-info" *ngIf="rule.legs.length == 1">SL</span>
                        </div>
                    </ng-template>
                </p-dropdown>

            </div>
            <div class="col-auto">
                <p>&nbsp;</p>
                <p-selectButton [options]="lookupService.orderTypes" formControlName="orderType" optionLabel="label"
                    id="orderTypes" optionValue="value"></p-selectButton>
            </div>


            <div class="col-auto">
                <p><label for="maturityDate">Maturity</label></p>
                <p-calendar id="maturityDate" formControlName="maturityDate" dateFormat="yy-mm-dd"
                    placeholder="Maturity" [style]="{'max-width': '50px !important;', 'min-width': '50px !important;'}"
                    dataType="string" appendTo="body" [showIcon]="true" [minDate]="minDate"></p-calendar>

            </div>

            <div class="col-auto">
                <p><label for="qty">Qty</label></p>
                <input type="number" formControlName="qty" class="form-control" name="qty" step="1" min="1"
                    [style]="{'max-width': '70px !important;'}" />
            </div>

            <div class="col-auto">
                <p><label for="strike">Strike</label></p>
                <input type="number" formControlName="strike" class="form-control" name="strike" step="0.01"
                    [style]="{'max-width': '70px !important;'}" />
            </div>

            <div class="col-auto">
                <!-- <p-inputNumber formControlName="price" mode="decimal" [size]="6" placeholder="Price"
                    [style]="{'max-width': '70px !important;'}" [minFractionDigits]="0" [maxFractionDigits]="4">
                </p-inputNumber> -->
                <p><label for="price">Price</label></p>
                <input type="number" formControlName="price" class="form-control" name="price" step=".01"
                    [style]="{'max-width': '70px !important;'}" />

            </div>
            <!-- <p-inputNumber formControlName="ratio" [size]="3" placeholder="Ratio"
                [style]="{'max-width': '30px !important;'}" [minFractionDigits]="0" [maxFractionDigits]="0">
            </p-inputNumber> -->
            <div class="col-auto">
                <p>&nbsp;</p>
                <button type="button" [disabled]="_formGroup.invalid" class="btn btn-secondary"
                    [ngClass]="{'btn-success': !_formGroup.invalid}" (click)=" this.setTemplate()">Add</button>
            </div>

        </div>
        <br>
    </div>
    <div class="d-flex justify-content-center">
        <div class="alert alert-warning" *ngIf="hasValidationError">
            <ul>
                <li *ngFor="let error of errorMessages">
                    {{error}}
                </li>
            </ul>
        </div>
    </div>

</div>