<div class="d-flex justify-content-center">
    <hotkeys [firstItemSelected]="false" (onChange)="setContract($event)"></hotkeys>
</div>
<div>
    <div class="d-flex justify-content-center">
        <div class="hstack gap-2">
            <div>
                <strong class="mr-1">Order #: &nbsp;&nbsp;</strong>
                <input type="text" pInputText placeholder="Order #" [(ngModel)]="filterModel.orderNo"
                    style="max-width: 100px !important;">
            </div>
            <div class="ml-2">
                <strong class="mr-1">From: &nbsp;&nbsp;</strong>
                <p-calendar [(ngModel)]="filterModel.fromTradeDate" dateFormat="yy-mm-dd" dataType="string"
                    [style]="{'max-width': '100px', 'min-width': '100px', 'width': '100px !important'}" />
            </div>
            <div class="ml-2">
                <strong class="mr-1">To: &nbsp;&nbsp;</strong>
                <p-calendar [(ngModel)]="filterModel.toTradeDate" dateFormat="yy-mm-dd" dataType="string"
                    [style]="{'max-width': '100px', 'min-width': '100px', 'width': '100px !important'}" />
            </div>
            <div class="ml-2">
                <strong class="mr-1">C/P: &nbsp;&nbsp;</strong>
                <p-dropdown [options]="lookupService.callPuts" [(ngModel)]="filterModel.callPut" optionLabel="label"
                    optionValue="label" [filter]="false" appendTo="body" [showClear]="true" placeholder="Select C/P"
                    [style]="{'max-width': '100px', 'min-width': '100px', 'width': '100px !important'}" />

            </div>
            <div class="ml-2">
                <strong class="mr-1">B/S: &nbsp;&nbsp;</strong>
                <p-dropdown [options]="lookupService.sides" [(ngModel)]="filterModel.side" optionLabel="label"
                    optionValue="label" [filter]="false" appendTo="body" [showClear]="true" placeholder="Select sides"
                    [style]="{'max-width': '100px', 'min-width': '100px', 'width': '100px !important'}" />

            </div>
            <div class="ml-2">
                <strong class="mr-1">Desk: &nbsp;&nbsp;</strong>
                <p-dropdown [options]="traderSwaps" [(ngModel)]="filterModel.traderSwap" optionLabel="name"
                    optionValue="name" [filter]="true" filterBy="name" appendTo="body" placeholder="Select Desk"
                    [showClear]="true"
                    [style]="{'max-width': '150px', 'min-width': '150px', 'width': '100px !important'}" />
            </div>

            <div class="btn-group">
                <button type="button" class="btn btn-primary" (click)="search()">Search</button>
            </div>
        </div>
    </div>
</div>


<p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRows" />
<table class="table table-bordered table-striped">
    <thead>
        <tr>
            <th>Id</th>
            <th></th>
            <th>Trade Date</th>
            <th>Contract</th>
            <th>Client</th>
            <th>Order #</th>
            <th>Ordered On</th>
            <th>Exec. On</th>
            <th>Maturity</th>
            <th>Desk</th>
            <th>B/S</th>
            <th>C/P</th>
            <th>Qty</th>
            <th>Strike</th>
            <th>Price</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let blotter of blotters">
            <td>{{blotter.id}}</td>
            <td>
                <a href="{{configService.get('recapUrl')}}{{blotter.id}}/{{blotter.traderSwap}}" target="_blank"
                    *ngIf="!blotter.emailSent">
                    <span class="pi pi-envelope"></span>
                </a>
                <!-- <button class="btn btn-link" *ngIf="!blotter.emailSent"
                    (click)="showEmailPreview(blotter.id, blotter.traderSwap)">
                    <span class="pi pi-envelope"></span>
                </button> -->
                <a *ngIf="blotter.emailSent">
                    <span class="pi text-success pi-check"></span>
                </a>
            </td>
            <td>{{blotter.tradeDate | date: 'yyyy-MM-dd'}}</td>
            <td>{{blotter.contract}}</td>
            <td>{{blotter.client}}</td>
            <td><strong>{{blotter.orderNo}}</strong></td>
            <td>{{blotter.eTradeOrderDateTime}}</td>
            <td>{{blotter.eTradeExecDateTime}}</td>
            <td>{{blotter.maturityDate| date: 'yyyy-MM-dd'}}</td>
            <td>{{blotter.traderSwap}}</td>
            <td>{{blotter.side}}</td>
            <td>{{blotter.callPut}}</td>
            <td>{{blotter.qty}}</td>
            <td>{{blotter.strike}}</td>
            <td>{{blotter.price}}</td>
        </tr>
    </tbody>
</table>

<div kendoWindowContainer></div>