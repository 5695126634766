import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PrintModel } from "../model/print/print-blotter.model";
import { TradeModel, TradeModelGroup } from "../model/trade.model";
import { ConfigurationService } from 'projects/shared/src/lib/services/config.service';
import { SharedService } from "./shared.service";

@Injectable()
export class FOBlotterService {

    private _url: string = '';
    private _httpClient: HttpClient;
    public constructor(httpClient: HttpClient,
        config: ConfigurationService,
        private _sharedService: SharedService) {
        this._httpClient = httpClient;
        this._url = config.get('apiUrl');
    }

    public getBlotters(model: any): Observable<any[]> {
        return this._httpClient.post<any[]>(`${this._url}/api/fo/blotter`, model);
    }

}