import { Injectable } from "@angular/core";
import { LookupsModel } from "projects/shared/src/lib/models/lookup.model";

@Injectable({
    providedIn: 'root'
})
export class LookupsService {
    public sides: LookupsModel[] = [
        {
            "label": "B",
            "value": "B",
            "styleClass": "p-button-success-dummy"
        },
        {
            "label": "S",
            "value": "S",
            "styleClass": "p-button-danger-dummy"
        }
    ];
    public callPuts: LookupsModel[] = [
        {
            "label": "C",
            "value": "C",
            "styleClass": "p-button-danger-dummy"
        },
        {
            "label": "P",
            "value": "P",
            "styleClass": "p-button-success-dummy"
        }
    ];

    public orderTypes: LookupsModel[] = [
        {
            "label": "LMT",
            "value": "LMT",
            "styleClass": "p-button-success-dummy"
        },
        {
            "label": "MKT",
            "value": "MKT",
            "styleClass": "p-button-danger-dummy"
        }
    ];

    public lifeCycleStatus: LookupsModel[] = [
        {
            "label": "PENDING",
            "value": "PENDING",
            "styleClass": "p-button-danger-dummy"
        },
        {
            "label": "LIVE",
            "value": "LIVE",
            "styleClass": "p-button-success-dummy"
        }
    ];

    public openClose: LookupsModel[] = [
        {
            "label": "O",
            "value": "O",
            "styleClass": "p-button-success-dummy"
        },
        {
            "label": "C",
            "value": "C",
            "styleClass": "p-button-danger-dummy"
        }
    ];

    public optionKind: LookupsModel[] = [
        {
            "label": "STAN",
            "value": "STAN",
            "styleClass": "p-button-success-dummy"
        },
        {
            "label": "FLEX",
            "value": "FLEX",
            "styleClass": "p-button-danger-dummy"
        }
    ];

    public settlementType: LookupsModel[] = [
        {
            "label": "AM",
            "value": "AM",
            "styleClass": "p-button-success-dummy"
        },
        {
            "label": "PM",
            "value": "PM",
            "styleClass": "p-button-danger-dummy"
        }
    ];

    public exerciseStyle: LookupsModel[] = [
        {
            "label": "A",
            "value": "A",
            "styleClass": "p-button-success-dummy"
        },
        {
            "label": "E",
            "value": "E",
            "styleClass": "p-button-danger-dummy"
        }
    ];
}