import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "modal-dialog",
  templateUrl: './modal-dialog.component.html',
})
export class ModalDialogComponent {
  @Input() public title!: string;
  @Input() public message!: string;
  @Input() public messages: string[] = [];

}