import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	constructor(private notificationService: NotificationService, private route: Router) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request)
			.pipe(
				retry(1),
				catchError((err: HttpErrorResponse) => {
					let message = '';
					let finalMessages: any[] = [];
					let title = 'Error Occured';
					if (err.error instanceof ErrorEvent) {
						// handle client-side error
						message = `Error: ${err.error.message}`;
						console.log(err.error);
					} else {
						switch (err.status) {
							case 0:
								message = 'No server found at the specified url. Please make sure backend service is running, more details are:';
								message += '\n\r' + err.error.message;
								break;
							case 401:
								message += '\n\r User session has expired, please login again';
								this.route.navigateByUrl("/");
								break;
							case 412:
							case 400:
								title = 'Required fields missing';
								if (Array.isArray(err.error.errors)) {
									let formatted = '<ul>';
									const ary = err.error as Array<string>;
									ary.forEach(message => {
										formatted += `<li>${message}</li>`;
									});
									formatted += '</ul>';
									message = formatted;
									this.notificationService.show({
										content: message,
										hideAfter: 600,
										position: { horizontal: "center", vertical: "top" },
										animation: { type: "fade", duration: 500 },
										type: { style: "info", icon: true },
									});
								} else {
									const messages = err.error.errors;
									const finalMessages: string[] = [];
									for (let [key, value] of Object.entries(messages)) {
										const newvalue: any[] = messages[key];
										newvalue.forEach(value => {
											finalMessages.push(`${key} - ${value}`);
										});
									}
									console.log(err);
									// if (finalMessages.length > 0) {
									// 	const dialogRef = this._dialogService.open({
									// 		title: title,
									// 		content: ModalDialogComponent,
									// 		actions: [
									// 			{ text: "Ok", themeColor: 'primary' }
									// 		]
									// 	});

									// 	const dialog = dialogRef.content.instance as ModalDialogComponent;
									// 	dialog.title = title;
									// 	dialog.messages = finalMessages;
									// }
								}
								break;

							/*case 417:
								debugger;
								message = err.error;
								// const dialogRef = this._dialogService.open({
								// 	title: title,
								// 	content: ModalDialogComponent,
								// 	actions: [
								// 		{ text: "Ok", themeColor: 'primary', actionLayout: "end" }
								// 	]
								// });
								// const dialog = dialogRef.content.instance as ModalDialogComponent;
								// dialog.title = title;

								if (Array.isArray(err.error)) {
									this._dialogService.showErrorsDialog("Validation Error", err.error);
									// dialog.messages = err.error;
								} else {
									this._dialogService.showErrorDialog("Validation Error", err.error);
								}
								break;*/
							case 500:
								message = err.error.message || err.statusText;
								break;
							default:
								message = "";
								console.log(err.error); //.error.message || err.statusText;
								throwError;

						}
					}

					if (message !== '') {
						this.notificationService.show({
							content: message,
							hideAfter: 600,
							position: { horizontal: "center", vertical: "top" },
							animation: { type: "fade", duration: 400 },
							type: { style: "error", icon: true },
						});

					}
					return throwError(err);
				})
			)
	}
}
