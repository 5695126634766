import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ReferenceDataService } from "../services/reference-data.service";
import { OverlayPanel } from "primeng/overlaypanel";
import { KeyValueModel } from "../model/key-value.model";

@Component({
    selector: 'bracket-times',
    templateUrl: './bracket-time.component.html'
})
export class BracketTimesComponent {

    @Input()
    public overlayPanel!: OverlayPanel;
    public bracketTimes: any[] = [];
    private BRACKET_SESSION: string = 'bracket-times';
    public leftValues: KeyValueModel[] = [];
    public rightValues: KeyValueModel[] = [];
    public selectedLeftValue!: any;
    public selectedRightValue!: any;
    @Output()
    public bracketTimeSelected: EventEmitter<string> = new EventEmitter<string>();
    public finalFormattedValue!: string;

    constructor(private _refService: ReferenceDataService) { }

    ngOnInit() {
        if (localStorage.getItem(this.BRACKET_SESSION) == null) {
            this._refService.getBracketTime()
                .subscribe((results: any) => {
                    localStorage.setItem(this.BRACKET_SESSION, JSON.stringify(results));
                    this.setLeftValues(results);
                    this.setRightValues(results);
                });
        } else {
            var bracketTime = localStorage.getItem(this.BRACKET_SESSION);
            const results: any = bracketTime != null ? JSON.parse(bracketTime) : [];
            this.setLeftValues(results);
            this.setRightValues(results);
        }
    }

    private setLeftValues(results: any) {
        results.forEach((item: any) => {
            this.leftValues.push({ key: item.description, value: `${item.description} (${item.startTime}: ${item.endTime})` });
        });
    }

    private setRightValues(results: any) {
        const uniqueValues = Array.from(new Set(results.map((x: any) => x.spreadCode)));
        uniqueValues.forEach((item: any) => {
            this.rightValues.push({ key: item, value: item });
        });

        if (this.rightValues.length == 1) {
            this.selectedRightValue = { key: uniqueValues[0], value: uniqueValues[0] };
        }
    }

    public onLeftRowSelect(event: any) {
        if (this.selectedRightValue) {
            this.finalFormattedValue = event.data.key + "," + this.selectedRightValue?.key;
        } else {
            this.finalFormattedValue = event.data.key;
        }

        if (this.rightValues.length == 1) {
            this.confirmFinalValue();
        }

    }

    public onRightRowSelect(event: any) {
        if (this.selectedLeftValue) {
            this.finalFormattedValue = this.selectedLeftValue.key + "," + event.data.key;
        } else {
            this.finalFormattedValue = event.data.key;
        }

    }

    public confirmFinalValue() {
        this.bracketTimeSelected.emit(this.finalFormattedValue);
        this.overlayPanel.hide();
    }

}