import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { BrokerViewModel } from "../model/broker.model";
import { StrategyRuleViewModel } from "../model/strategy-rule.model";
import { ConfigurationService } from 'projects/shared/src/lib/services/config.service';

@Injectable({
    providedIn: 'root'
})
export class ReferenceDataService {

    private _url: string = '';
    constructor(private _httpClient: HttpClient, config: ConfigurationService) {
        this._url = config.get('apiUrl');
    }

    // public getContracts(): Observable<any> {
    //     return this._httpClient.get<Observable<any>>(`${this._url}/api/ReferenceData/contracts`);
    // }

    public getTraderSwaps(): Observable<any> {
        return this._httpClient.get<Observable<any>>(`${this._url}/api/ReferenceData/traderswaps`);
    }

    public getFullBBCode(contract: string, maturityDate: string): Observable<any> {
        return this._httpClient.get<Observable<any>>(`${this._url}/api/ReferenceData/fo/contract/${contract}/maturityDate/${maturityDate}`);
    }

    public getStrategyRule(): Observable<StrategyRuleViewModel[]> {
        return this._httpClient.get<StrategyRuleViewModel[]>(`${this._url}/api/referenceData/strategyRules`);
    }

    public getBrokers(nameOrSymbol?: string): Observable<BrokerViewModel[]> {
        return this._httpClient.get<BrokerViewModel[]>(`${this._url}/api/referenceData/brokers?nameOrSymbol=${nameOrSymbol}`);
    }

    public getStrikesByContract(contractname: string): Observable<any> {
        return this._httpClient.get<any>(`${this._url}/api/referenceData/strikes/by/contract/${contractname}`);
    }

    public getBracketTime(): Observable<any> {
        return this._httpClient.get<any>(`${this._url}/api/referenceData/bracketTimes`);
    }

    public getHotKeyContracts(): Observable<any> {
        return this._httpClient.get<any>(`${this._url}/api/referenceData/contracts/hotkeys`);
    }

    public getLegalEntities(): Observable<any> {
        return this._httpClient.get<any>(`${this._url}/api/referenceData/accounts`);
    }
}