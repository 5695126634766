import { Injectable } from "@angular/core";
import { TokenResponseModel, UserDetails } from "../model/user-detail.model";
import { BehaviorSubject, Subject } from "rxjs";
import { EmailPreviewRequest } from "../model/email-preview-request.model";
import { AccountService } from "./account.service";

@Injectable({
    providedIn: 'root',
})
export class SharedService {

    public storageKey: string = "userDetails";
    public orders: EmailPreviewRequest[] = [];
    public subject = new Subject<any>();
    private messageSource = new BehaviorSubject(this.orders);
    currentMessage = this.messageSource.asObservable();
    private EMAIL_PREVIEW_ORDERS: string = 'previewSelectedItems';

    constructor(private accountService: AccountService) {

    }

    public get getUserDetails(): UserDetails {
        const token = localStorage.getItem('userDetails');

        if (token == null) {

        }
        const tokenModelForUser: UserDetails = token != null ? JSON.parse(token) : new UserDetails();
        //console.log(tokenModelForUser, "Token model");
        return tokenModelForUser;
    }

    public set setUserToken(tokenModel: any) {
        localStorage.setItem(this.storageKey, JSON.stringify(tokenModel));
        //localStorage.setItem("access_Token", tokenModel.access_Token);

    }

    public logout() {
        localStorage.clear();
        window.location.href = "../home";
    }

    public isLoggedIn(): boolean {
        return localStorage.getItem('userDetails') !== null;
    }

    public addOrders(orders: EmailPreviewRequest[]) {
        this.messageSource.next(orders);
    }

    public setEmailPreviewOrders(forEmailPreview: any[]) {
        localStorage.setItem(this.EMAIL_PREVIEW_ORDERS, JSON.stringify(forEmailPreview));
    }

    public removeEmailPreviewOrders() {
        localStorage.removeItem(this.EMAIL_PREVIEW_ORDERS);
    }

    public getEmailPreviewOrders(): any[] | null {
        const value = localStorage.getItem(this.EMAIL_PREVIEW_ORDERS) !== null ? localStorage.getItem(this.EMAIL_PREVIEW_ORDERS) : null;

        if (value == null) return null;

        return JSON.parse(value);
    }

}