import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlotterService } from './services/blotter.service';
import { ReferenceDataService } from './services/reference-data.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalDialogComponent } from './shared/modal-dialog/modal-dialog.component';
import { CommonModule } from '@angular/common';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { EmailPreviewComponent } from './email-preview/email-preview.component';
import { EmailService } from './services/email.service';
import { SafePipe } from './pipes/safe.pipe';
import { SharedService } from './services/shared.service';
import { TopNavigationComponent } from './top-navigation/top-navigation.component';
import { LoginComponent } from './login/login.component';
import { AccountService } from './services/account.service';
import { ErrorInterceptor } from './interceptors/error-interceptor.interceptor';
import { AuthGuardService } from './services/guards/auth-guard.service';
import { NumericDirective } from './directive/numeric/numeric-directive.directive';
import { PriceSummaryComponent } from './price-summary/price-summary.component';
import { PrintBlotterComponent } from './print-blotter/print-blotter.component';
import { TableModule } from 'primeng/table';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { PaginatorModule } from 'primeng/paginator';
import { ConfirmedBlotterComponent } from './confirmed-blotter/confirmed-blotter.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { BracketTimesComponent } from './bracket-time/bracket-time.component';

import {
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard,
    MsalInterceptor, MsalRedirectComponent, MsalService
} from '@azure/msal-angular';
import { MSALInstanceFactory } from 'projects/shared/src/lib/auth/msal-instance.factory';
import { MSALGuardConfigFactory } from 'projects/shared/src/lib/auth/guard-config.factory';
import { MSALInterceptorConfigFactory } from 'projects/shared/src/lib/auth/msal-interceptor-config.factory';
import { ConfigurationService } from 'projects/shared/src/lib/services/config.service';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AddBlotterComponent } from './blotter-form/add-blotter/add-blotter-form.component';
import { DateTimeComponent } from './shared/component/date-time/date-time.component';
import { NumericInputComponent } from './shared/component/numeric-input/numeric-input.component';
import { BlotterListComponent } from './blotter-list/blotter-list.component';
import { BlotterFormComponent } from './blotter-form/blotter-form.component';
import { HotKeysComponent } from './shared/component/hotkeys/hotkeys.component';
import { CancelOrderComponent } from './blotter-form/cancel-order/cancel-order.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AuditListComponent } from './audit-list/audit-list..component';
import { FOBlotterListComponent } from './fo-blotter-list/fo-blotter-list.component';
import { FOBlotterService } from './services/fo-blotter.service';
import { HttpStatusInterceptor } from './interceptors/http-status-interceptor.interceptor';
import { ModifyOrderComponent } from './blotter-form/modify-order/modify-order.component';
import { FloatLabelModule } from 'primeng/floatlabel';
import { TooltipModule } from 'primeng/tooltip';
@NgModule({
    declarations: [
        AppComponent,
        ModalDialogComponent,
        EmailPreviewComponent,
        SafePipe,
        TopNavigationComponent,
        LoginComponent,
        NumericDirective,
        PriceSummaryComponent,
        PrintBlotterComponent,
        ConfirmedBlotterComponent,
        BracketTimesComponent,
        AddBlotterComponent,
        DateTimeComponent,
        NumericInputComponent,
        BlotterListComponent,
        BlotterFormComponent,
        HotKeysComponent,
        CancelOrderComponent,
        AuditListComponent,
        FOBlotterListComponent,
        ModifyOrderComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [CommonModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NotificationModule,
        TableModule,
        AutoCompleteModule,
        CalendarModule,
        InputMaskModule,
        InputNumberModule,
        PaginatorModule,
        OverlayPanelModule,
        ScrollPanelModule,
        SelectButtonModule,
        ButtonModule,
        DynamicDialogModule,
        InputTextModule,
        FloatLabelModule,
        TooltipModule
    ],
    providers: [
        DialogService,
        ConfigurationService,
        {
            provide: APP_INITIALIZER,
            useFactory: (config: ConfigurationService) => () => config.load(),
            deps: [ConfigurationService],
            multi: true
        },
        ReferenceDataService,
        BlotterService,
        EmailService,
        SharedService,
        AccountService,
        FOBlotterService,
        AuthGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        // {
        // 	provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
        // },
        {
            provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: HttpStatusInterceptor, multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
            deps: [ConfigurationService]
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
            deps: [ConfigurationService]
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
            deps: [ConfigurationService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class FloorEntryModule { }
