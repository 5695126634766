import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as moment from "moment";

export function customDateValidator(controlName: string): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

        //console.log(controlName, control.value);
        const value = control.value;

        const dateToCompare = control.get(controlName)?.value;

        if (!value || value == "")
            return null;

        //const _errorMessages: string[] = [];

        const dateToCompareAsMoment = controlName == null || controlName == "" ? moment() : moment(dateToCompare);
        let isDateValid = false;
        if (!moment(dateToCompare).isValid()) {
            isDateValid = false;
            return {
                value: value,
                isValid: isDateValid,
                message: null,
                errorMessages: `The date you have entered is not valid`
            };
        } else {
            if (dateToCompare != null) {

                //check if date is >=  than now - 10 mins
                const nowMinus10Min = moment().subtract(10, 'minute');

                if (moment(value).isBefore(nowMinus10Min)) {
                    isDateValid = false;
                    return {
                        value: value,
                        isValid: isDateValid,
                        message: null,
                        errorMessages: `The date cannot be < Now - 10 min (${nowMinus10Min.format("YYYY-MM-DD HH:mm:ss.SSS")})`
                    }
                }

                if (moment(value).isAfter(moment())) {
                    isDateValid = false;
                    return {
                        value: value,
                        isValid: isDateValid,
                        message: null,
                        errorMessages: `The date cannot be > current date and time`
                    };
                }

                if (moment(value).isBefore(moment(dateToCompareAsMoment))) {
                    isDateValid = false;
                    return {
                        value: value,
                        isValid: isDateValid,
                        message: null,
                        errorMessages: `The date cannot be < order time ${dateToCompare}`
                    };
                }
            }
        }

        return null;
    }
}
