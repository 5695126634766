import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PrintModel } from "../model/print/print-blotter.model";
import { TradeModel, TradeModelGroup } from "../model/trade.model";
import { ConfigurationService } from 'projects/shared/src/lib/services/config.service';
import { SharedService } from "./shared.service";

@Injectable()
export class BlotterService {

    private _url: string = '';
    private _httpClient: HttpClient;
    public constructor(httpClient: HttpClient,
        config: ConfigurationService,
        private _sharedService: SharedService) {
        this._httpClient = httpClient;
        this._url = config.get('apiUrl');
    }

    public saveBlotter(model: any): Observable<TradeModel[]> {
        return this._httpClient.post<TradeModel[]>(`${this._url}/api/blotter/`, model);
    }

    public saveBlotters(models: any[], username: string): Observable<TradeModel[]> {
        return this._httpClient.post<TradeModel[]>(`${this._url}/api/blotter/save/list?username=${username}`, models);
    }

    public saveBlottersAndReturnAsGroup(models: any[], username: string, startEvents: boolean = false): Observable<TradeModelGroup[]> {
        return this._httpClient.post<TradeModelGroup[]>(`${this._url}/api/blotter/save/list?username=${username}&startEvents=${startEvents}`, models);
    }

    // public cancel(models: any[], username: string): Observable<TradeModelGroup[]> {
    //     return this._httpClient.post<TradeModelGroup[]>(`${this._url}/api/blotter/cancel?username=${username}`, models);
    // }

    public cancel(blotterId: number, cancelRequestOn: string, clientCancellationAckOn: string, cancelledOn: string): Observable<any> {
        return this._httpClient.post<any>(`${this._url}/api/blotter/cancel`, {
            id: blotterId,
            cancelRequestOn: cancelRequestOn,
            clientCancellationAckOn: clientCancellationAckOn,
            cancelledOn: cancelledOn,
            username: this._sharedService.getUserDetails.username
        });
    }


    public validateConfirmationOneOrderAndTraderSwap(models: any[]): Observable<TradeModel[]> {
        return this._httpClient.post<TradeModel[]>(`${this._url}/api/blotter/validate/confirmation`, models);
    }

    public getDraftBlotters(broker?: string): Observable<TradeModel[]> {
        return this._httpClient.get<TradeModel[]>(`${this._url}/api/blotter/draft?broker=${broker}`);
    }

    public getConfirmedBlotters(model: any): Observable<TradeModel[]> {
        return this._httpClient.post<TradeModel[]>(`${this._url}/api/blotter/search/confirmed`, model);
    }

    public getConfirmedBlottersGroupedByOrderNo(model: any): Observable<TradeModelGroup[]> {
        return this._httpClient.post<TradeModelGroup[]>(`${this._url}/api/blotter/search/confirmed/groupby/orderNo`, model);
    }

    public searchDraftBlotters(model: any): Observable<TradeModel[]> {
        return this._httpClient.post<TradeModel[]>(`${this._url}/api/blotter/search/draft`, model);
    }

    public getDraftOrders(model: any): Observable<TradeModelGroup[]> {
        return this._httpClient.post<TradeModelGroup[]>(`${this._url}/api/blotter/search/draft/groupby/orders`, model);
    }

    public addNewBlotter(data: any, strategy?: string | null): Observable<any> {

        if (strategy == undefined || strategy == null) {
            return this._httpClient.post(`${this._url}/api/strategy/new`, data);
        }

        return this._httpClient.post(`${this._url}/api/strategy/new/${strategy}`, data);
    }

    public copyBlotters(ids: number[], orderNo: string, orderTime: string, orderTimeUtc: string): Observable<any> {
        return this._httpClient.post(`${this._url}/api/blotter/copy/orderNo/${orderNo}/orderTime/${orderTime}/utc/${orderTimeUtc}`, ids);
    }

    public getSummary(status: string): Observable<any> {
        return this._httpClient.get(`${this._url}/api/blotter/price/summary/${status}`);
    }

    public getPrintBlotters(model: any): Observable<PrintModel[]> {
        return this._httpClient.post<PrintModel[]>(`${this._url}/api/print`, model);
    }

    public getPrintBlottersById(ids: number[], brokerName?: string): Observable<PrintModel[]> {
        return this._httpClient.post<PrintModel[]>(`${this._url}/api/print/by/id/brokerName/${brokerName}`, ids);
    }

    public validateEntryForm(tradeModel: TradeModel): Observable<string[]> {
        return this._httpClient.post<string[]>(`${this._url}/api/strategy/validate`, tradeModel);
    }
    public delete(orderNo: string, username: string) {
        return this._httpClient.delete(`${this._url}/api/blotter/delete/${orderNo}/${username}`);
    }

    public getAuditDetails(blotterId: number): Observable<any> {
        return this._httpClient.get<any>(`${this._url}/api/blotter/audit/${blotterId}`);
    }

    public getBlotterById(blotterId: number): Observable<TradeModel> {
        return this._httpClient.get<TradeModel>(`${this._url}/api/blotter/id/${blotterId}`);
    }

    public getBlottersByOrderNo(orderNo: string): Observable<TradeModelGroup> {
        return this._httpClient.get<TradeModelGroup>(`${this._url}/api/blotter/search/draft/groupby/orders/${orderNo}`);
    }

    public copyOrder(orderNo: string, username: string): Observable<any> {
        return this._httpClient.get<any>(`${this._url}/api/blotter/copy/${orderNo}/${username}`);
    }
}